





















































import Vue from 'vue'
import Footer from '~/components/commons/organisms/Footer.vue'
import BackToTopButton from '~/components/commons/atoms/BackToTopButton.vue'
import Button from '~/components/commons/atoms/Button.vue'

export default Vue.extend({
  components: {
    Footer,
    BackToTopButton,
    Button
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    leadText: {
      type: String,
      default: undefined
    },
    buttonText: {
      type: String,
      default: undefined
    },
    buttonWidth: {
      type: String,
      default: undefined
    },
    to: {
      type: String,
      default: undefined
    },
    leadCentered: {
      type: Boolean,
      default: false
    },
    showLogoutAndReloadButton: {
      type: Boolean,
      default: false
    }
  }
})
