import HttpRepository from './httpRepository'

export default class FileRepository extends HttpRepository {
  async upload(params: {
    file: string
    filename: string
    contentType: string
  }): Promise<string> {
    const data = await this.post('/api/files', params)
    return data.url
  }

  async uploadImage(image: string): Promise<string> {
    const data = await this.post('/api/images', { image })
    return data.url
  }
}
