export default async ({ params, redirect, $groupRepository }) => {
  const groupId = params.id
  if (!params.id) redirect('/group')
  try {
    const belongedGroups = await $groupRepository.findBelongedGroups()
    const belongedGroupObjectIds = belongedGroups
      .filter(group => !group.isInvited)
      .map(group => group.objectId)
    if (!belongedGroupObjectIds.includes(groupId)) {
      redirect(`/group/${groupId}`)
    }
  } catch {
    groupId ? redirect(`/group/${groupId}`) : redirect('/group')
  }
}
