const defaultState = () => ({
  email: '',
  suggestions: [],
  firstnameJa: '',
  lastnameJa: '',
  firstnameJaFurigana: '',
  lastnameJaFurigana: '',
  gender: null,
  birthdayYear: '',
  birthdayMonth: '',
  birthdayDay: '',
  certificationYear: '',
  certificationMonth: '',
  certificationDate: '',
  registeredNumber: '',
  hospitals: [
    {
      prefectureCode: '',
      areaId: '',
      hospitalId: '',
      name: '',
      hospitalPref: '',
      hospitalCity: '',
      start: ''
    }
  ],
  category: '',
  subCategory: '',
  candidateDoctors: [],
  university: '',
  graduationYear: '',
  hasAlfresaCode: true,
  alfresaCode: '',
  doctorExpertCategory: [],
  searchPaperMeshList: [],
  surgeryMeshList: [],
  unacceptableMeshList: [],
  introduction: '',
  password: '',
  isTemporary: true,
  hideUniversity: false,
  objectId: '',
  doctorObjectId: undefined,
  hideCertificationYear: false,
  hideGender: false,
  // register-email, confirm, register-name, select-candidate, profile, additional-profile
  step: null,
  isCandidateSelected: false,
  resisteredUser: null,
  originalEnrollmentPeriodList: null
})

export const state = () => ({
  email: '',
  suggestions: [],
  firstnameJa: '',
  lastnameJa: '',
  firstnameJaFurigana: '',
  lastnameJaFurigana: '',
  gender: null,
  birthdayYear: '',
  birthdayMonth: '',
  birthdayDay: '',
  certificationYear: '',
  certificationMonth: '',
  certificationDate: '',
  registeredNumber: '',
  hospitals: [
    {
      prefectureCode: '',
      areaId: '',
      hospitalId: '',
      name: '',
      hospitalPref: '',
      hospitalCity: '',
      start: ''
    }
  ],
  category: '',
  subCategory: '',
  candidateDoctors: [],
  university: '',
  graduationYear: '',
  hasAlfresaCode: true,
  alfresaCode: '',
  doctorExpertCategory: [],
  searchPaperMeshList: [],
  surgeryMeshList: [],
  unacceptableMeshList: [],
  introduction: '',
  password: '',
  isTemporary: true,
  hideUniversity: false,
  objectId: '',
  doctorObjectId: undefined,
  hideCertificationYear: false,
  hideGender: false,
  // register-email, confirm, register-name, select-candidate, profile, additional-profile
  step: null,
  isCandidateSelected: false,
  resisteredUser: null
})

export const getters = {
  getBirthday(state) {
    return state.birthdayYear && state.birthdayMonth && state.birthdayDay
      ? [
          state.birthdayYear,
          state.birthdayMonth.toString().padStart(2, '0'),
          state.birthdayDay.toString().padStart(2, '0')
        ].join('/')
      : undefined
  },
  hospitalId(state) {
    return state.hospitals[0]?.hospitalId || ''
  },
  hospitalName(state) {
    return state.hospitals[0]?.name || ''
  },
  hospitalPref(state) {
    return state.hospitals[0]?.hospitalPref || ''
  },
  hospitalCity(state) {
    return state.hospitals[0]?.hospitalCity || ''
  }
}

export const mutations = {
  setEmail(state, email) {
    state.email = email
  },
  setHospitalSuggestion(state, hospitals) {
    state.suggestions = hospitals
  },
  resetState(state) {
    Object.assign(state, defaultState())
  },
  resetProfile(state) {
    state.firstnameJaFurigana = ''
    state.lastnameJaFurigana = ''
    state.gender = null
    state.certificationYear = ''
    state.certificationMonth = ''
    state.certificationDate = ''
    state.registeredNumber = ''
    state.hospitals = [
      {
        prefectureCode: '',
        areaId: '',
        hospitalId: '',
        name: '',
        hospitalPref: '',
        hospitalCity: '',
        start: ''
      }
    ]
    state.category = ''
    state.subCategory = ''
    state.birthdayYear = ''
    state.birthdayMonth = ''
    state.birthdayDay = ''
    state.hospitalName = ''
    state.hospitalCity = ''
    state.hospitalPref = ''
    state.hideCertificationYear = false
    state.hideGender = false
    state.isCandidateSelected = false
  },
  resetSelectedCard(state) {
    state.gender = null
    state.hospitals = [
      {
        prefectureCode: '',
        areaId: '',
        hospitalId: '',
        name: '',
        hospitalPref: '',
        hospitalCity: '',
        start: ''
      }
    ]
    state.category = ''
    state.subCategory = ''
  },
  setProfile(
    state,
    {
      firstnameJa,
      lastnameJa,
      firstnameJaFurigana,
      lastnameJaFurigana,
      gender,
      certificationYear,
      certificationMonth,
      certificationDate,
      registeredNumber,
      hospitals,
      category,
      subCategory,
      birthdayYear,
      birthdayMonth,
      birthdayDay,
      hospitalName,
      hospitalCity,
      hospitalPref,
      hideCertificationYear,
      hideGender,
      searchPaperMeshList,
      originalEnrollmentPeriodList
    }
  ) {
    state.firstnameJa = firstnameJa
    state.lastnameJa = lastnameJa
    state.firstnameJaFurigana = firstnameJaFurigana
    state.lastnameJaFurigana = lastnameJaFurigana
    state.gender = gender
    state.certificationYear = certificationYear
    state.certificationMonth = certificationMonth
    state.certificationDate = certificationDate
    state.registeredNumber = registeredNumber
    state.hospitals = hospitals
    state.category = category
    state.subCategory = subCategory
    state.birthdayYear = birthdayYear
    state.birthdayMonth = birthdayMonth
    state.birthdayDay = birthdayDay
    state.hospitalName = hospitalName
    state.hospitalCity = hospitalCity
    state.hospitalPref = hospitalPref
    state.hideCertificationYear = hideCertificationYear
    state.hideGender = hideGender
    state.searchPaperMeshList = searchPaperMeshList
    state.originalEnrollmentPeriodList = originalEnrollmentPeriodList
  },
  setCandidateDoctors(state, doctors) {
    state.candidateDoctors = doctors
  },
  confirmCandidate(state, { doctor }) {
    state.doctorObjectId = doctor?.objectId
  },
  setHideUniversity(state, hideUniversity) {
    state.hideUniversity = !!hideUniversity
  },
  setUniversity(state, university) {
    state.university = university
  },
  setGraduationYear(state, graduationYear) {
    state.graduationYear = graduationYear
  },
  setHasAlfresaCode(state, hasAlfresaCode) {
    state.hasAlfresaCode = hasAlfresaCode
  },
  setAlfresaCode(state, alfresaCode) {
    state.alfresaCode = alfresaCode
  },
  setDoctorExpertCategory(state, doctorExpertCategory) {
    state.doctorExpertCategory = doctorExpertCategory
  },
  setSearchPaperMeshList(state, searchPaperMeshList) {
    state.searchPaperMeshList = searchPaperMeshList
  },
  setSurgeryMeshList(state, surgeryMeshList) {
    state.surgeryMeshList = surgeryMeshList
  },
  setUnacceptableMeshList(state, unacceptableMeshList) {
    state.unacceptableMeshList = unacceptableMeshList
  },
  setPassword(state, password) {
    state.password = password
  },
  setIntroduction(state, introduction) {
    state.introduction = introduction
  },
  setIsTemporary(state, isTemporary) {
    state.isTemporary = isTemporary
  },
  setObjectId(state, objectId) {
    state.objectId = objectId
  },
  setStep(state, payload) {
    state.step = payload
  },
  resetStep(state) {
    state.step = null
  },
  setFirstnameJa(state, payload) {
    state.firstnameJa = payload
  },
  setLastnameJa(state, payload) {
    state.lastnameJa = payload
  },
  setFirstnameJaFurigana(state, payload) {
    state.firstnameJaFurigana = payload
  },
  setLastnameJaFurigana(state, payload) {
    state.lastnameJaFurigana = payload
  },
  setBirthdayYear(state, payload) {
    state.birthdayYear = payload
  },
  setBirthdayMonth(state, payload) {
    state.birthdayMonth = payload
  },
  setBirthdayDay(state, payload) {
    state.birthdayDay = payload
  },
  setGender(state, payload) {
    state.gender = payload
  },
  setCertificationYear(state, payload) {
    state.certificationYear = payload
  },
  setCertificationMonth(state, payload) {
    state.certificationMonth = payload
  },
  setcertificationDate(state, payload) {
    state.certificationDate = payload
  },
  setRegisteredNumber(state, payload) {
    state.registeredNumber = payload
  },
  setCategory(state, payload) {
    state.category = payload
  },
  setSubCategory(state, payload) {
    state.subCategory = payload
  },
  setHideCertificationYear(state, payload) {
    state.hideCertificationYear = payload
  },
  setHideGender(state, payload) {
    state.hideGender = payload
  },
  setHospitals(state, payload) {
    state.hospitals = payload
  },
  setIsCandidateSelected(state, payload) {
    state.isCandidateSelected = payload
  },
  setCandidateProfile(
    state,
    {
      originalEnrollmentPeriodList,
      hospitalName,
      hospitalCity,
      hospitalPref,
      category,
      subCategory,
      hospitals,
      searchPaperMeshList
    }
  ) {
    state.originalEnrollmentPeriodList = originalEnrollmentPeriodList
    state.hospitalName = hospitalName
    state.hospitalCity = hospitalCity
    state.hospitalPref = hospitalPref
    state.category = category
    state.subCategory = subCategory
    state.hospitals = hospitals
    state.searchPaperMeshList = searchPaperMeshList
  },
  resetCandidateProfile(state) {
    state.originalEnrollmentPeriodList = []
    state.hospitalName = ''
    state.hospitalCity = ''
    state.hospitalPref = ''
    state.category = ''
    state.subCategory = ''
    state.hospitals = [0]
    state.searchPaperMeshList = []
  },
  changeEditHospital(state, payload) {
    const index = payload.index
    const prefectureCode = payload.hospital.prefectureCode
    const medicalAreaId = payload.hospital.medicalAreaId
    const hospitalId = payload.hospital.hospitalId
    const name = payload.hospital.name
    const hospitalPref = payload.hospital?.hospitalPref
    const hospitalCity = payload.hospital?.hospitalCity
    state.hospitals = state.hospitals.map((item, i) => {
      if (i !== index) {
        return item
      }
      return {
        ...item,
        prefectureCode,
        areaId: medicalAreaId,
        hospitalId,
        name,
        hospitalPref,
        hospitalCity
      }
    })
  },
  setRegisteredUser(state, payload) {
    state.resisteredUser = payload
  }
}

export const actions = {
  setStep({ commit }, step) {
    commit('setStep', step)
  },
  resetStep({ commit }) {
    commit('setStep', null)
  },
  resetState({ commit }) {
    commit('resetState')
  },
  resetSelectedCard({ commit }) {
    commit('resetSelectedCard')
    commit('resetCandidateProfile')
  },
  setCandidateProfile({ commit }, payload) {
    const hasHospital = payload?.hospitals?.length > 0
    const hospitals = hasHospital
      ? payload?.hospitals.map(item => {
          return {
            prefectureCode: item?.prefectureCode || '',
            areaId: item?.medicalAreaId || '',
            hospitalId: item?.hospitalId || '',
            name: item?.name || '',
            hospitalPref: item?.prefecture || '',
            hospitalCity: item?.city || '',
            start: ''
          }
        })
      : [
          {
            prefectureCode: '',
            areaId: '',
            hospitalId: '',
            name: '',
            hospitalPref: '',
            hospitalCity: '',
            start: ''
          }
        ]
    const hospital = hospitals[0]
    const originalEnrollmentPeriodList = hasHospital
      ? hospitals.map(hospital => ({
          ...hospital,
          isWorking: true,
          startDate: hospital.start.replace('-', '.')
        }))
      : undefined
    commit('setCandidateProfile', {
      originalEnrollmentPeriodList,
      hospitalName: hospital?.name || '',
      hospitalCity: hospital?.hospitalCity || '',
      hospitalPref: hospital?.hospitalPref || '',
      category:
        payload?.categories?.length > 0
          ? payload?.categories[0].categoryId
          : '',
      subCategory:
        payload?.categories?.length > 0
          ? payload?.categories[0].subCategoryId
          : '',
      hospitals,
      searchPaperMeshList: payload?.specialtyList ?? []
    })
    commit('setIsCandidateSelected', true)
  },
  async sendMailAuthCode({ commit }, { email }) {
    await this.$axios.$post('/api/isValidEmail', { email })
    await this.$axios.$post('/api/sendMailAuthCode', { email })
    commit('setEmail', email)
  },
  async fetchHospitalSuggestion({ commit }, areaId) {
    commit('setHospitalSuggestion', [])
    const data = await this.$axios.$get(`/api/hospitals/suggestions/${areaId}`)
    commit('setHospitalSuggestion', data)
  },
  setProfile(
    { commit },
    {
      firstnameJa,
      lastnameJa,
      gender,
      certificationYear,
      certificationMonth,
      certificationDate,
      registeredNumber,
      hospitals,
      category,
      subCategory,
      birthdayYear,
      birthdayMonth,
      birthdayDay,
      hospitalName,
      hospitalCity,
      hospitalPref,
      hideCertificationYear,
      hideGender,
      searchPaperMeshList
    }
  ) {
    commit('setProfile', {
      firstnameJa,
      lastnameJa,
      gender,
      certificationYear,
      certificationMonth,
      certificationDate,
      registeredNumber,
      hospitals,
      category,
      subCategory,
      birthdayYear,
      birthdayMonth,
      birthdayDay,
      hospitalName,
      hospitalCity,
      hospitalPref,
      hideCertificationYear,
      hideGender,
      searchPaperMeshList
    })
  },
  async isValidDoctor({ state, commit, getters }) {
    const data = await this.$authRepository
      .isValidDoctor({
        firstnameJa: state.firstnameJa,
        lastnameJa: state.lastnameJa,
        firstnameKana: state.firstnameJaFurigana,
        lastnameKana: state.lastnameJaFurigana,
        gender: state.gender,
        certificationYear: state.certificationYear.toString(),
        certificationMonth: state.certificationMonth
          .toString()
          .padStart(2, '0'),
        certificationDate: state.certificationDate.toString().padStart(2, '0'),
        registeredNumber: state.registeredNumber,
        email: state.email,
        category2: state.category,
        subCategoryId: state.subCategory,
        birthday: getters.getBirthday
      })
      .catch(error => {
        throw new Error(error.response?.data || error?.message || error)
      })
    commit('setIsTemporary', data.isTemporary)
  },
  setDoctorName({ commit }, payload) {
    commit('setFirstnameJa', payload.firstnameJa)
    commit('setLastnameJa', payload.lastnameJa)
  },
  setHospitals({ commit }, payload) {
    commit('setHospitals', payload)
  },
  changeEditHospital({ commit }, payload) {
    commit('changeEditHospital', payload)
  },
  async findDoctorCandidate({ state, commit }) {
    const data = await this.$axios.$post('/api/doctor/candidate', {
      firstnameJa: state.firstnameJa,
      lastnameJa: state.lastnameJa
    })
    commit('setCandidateDoctors', data)
  },
  async registerUser({ state, commit, getters }) {
    const data = await this.$authRepository
      .registerUser({
        email: state.email,
        password: state.password,
        firstnameJa: state.firstnameJa,
        lastnameJa: state.lastnameJa,
        firstnameKana: state.firstnameJaFurigana,
        lastnameKana: state.lastnameJaFurigana,
        certificationYear: state.certificationYear,
        certificationMonth: state.certificationMonth,
        certificationDate: state.certificationDate,
        registeredNumber: state.registeredNumber,
        gender: state.gender,
        category2: state.category,
        subCategoryId: state.subCategory,
        isTemporary: state.isTemporary,
        birthday: getters.getBirthday,
        hospitalName: getters.hospitalName,
        hospitalCity: getters.hospitalCity,
        hospitalPref: getters.hospitalPref,
        code: state.alfresaCode
      })
      .catch(error => {
        throw new Error(error?.message || error)
      })
    commit('setObjectId', data.objectId)
    commit('setRegisteredUser', data)
  },
  async createDcsrDoctor({ state, getters }) {
    const enrollmentPeriodList = state.hospitals.map(hospital => ({
      ...hospital,
      isWorking: true,
      startDate: hospital.start.replace('-', '.')
    }))

    await this.$authRepository
      .createDoctor({
        user: state.resisteredUser,
        sessionToken: state.resisteredUser.sessionToken,
        doctorObjectId: state.doctorObjectId,
        password: state.password,
        category2: state.category,
        subCategoryId: state.subCategory,
        doctorExpertCategory: state.doctorExpertCategory,
        searchPaperMeshList: state.searchPaperMeshList,
        surgeryMeshList: state.surgeryMeshList,
        unacceptableMeshList: state.unacceptableMeshList,
        introduction: state.introduction,
        university: state.university,
        graduationYear: state.graduationYear,
        alfresaCode: state.alfresaCode,
        originalEnrollmentPeriodList:
          state.originalEnrollmentPeriodList ?? undefined,
        enrollmentPeriodList,
        hideUniversity: state.hideUniversity,
        birthday: getters.getBirthday,
        hospitalId: getters.hospitalId,
        hospitalName: getters.hospitalName,
        hospitalPrefecture: getters.hospitalPref,
        hideCertificationYear: state.hideCertificationYear,
        hideGender: state.hideGender,
        isTemporary: state.isTemporary
      })
      .catch(error => {
        throw new Error(error?.message || error)
      })
  }
}
