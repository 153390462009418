import CryptoJS from 'crypto-js'

import { AnalysisErrorType } from 'aws-sdk/clients/quicksight'
import HttpRepository from './httpRepository'
import constant from '~/helper/constant'

const decryptMessage = (message: string, key: string): string =>
  CryptoJS.AES.decrypt(message, key).toString(CryptoJS.enc.Utf8)
const encryptMessage = (message: string, key: string): string =>
  CryptoJS.AES.encrypt(message, key).toString()

export default class ChatRepository extends HttpRepository {
  private mqttClient: any

  private didSetMqttClient: boolean = false

  private sessionId: string | undefined = undefined

  private sessionCallback: Function | undefined = undefined

  private sessionKey: string = ''

  private currentSubscribeTopicName: string | undefined = undefined

  async initializeMqttClient(userObjectId: string): Promise<void> {
    // clientで呼ばないとエラーになってしまうため
    const awsIot = require('aws-iot-device-sdk')
    const clientId = `${userObjectId}@xmdev-${Date.now()}`
    const { accessKeyId, secretAccessKey, sessionToken } = await this._getCert()

    const mqttClient = awsIot.device({
      clientId,
      host: 'a2qare4ca4lmz2-ats.iot.ap-northeast-1.amazonaws.com',
      region: 'ap-northeast-1',
      protocol: 'wss',
      maximumReconnectTimeMs: 8000,
      // debug: process.env.STAGE === 'local',
      accessKeyId,
      secretKey: secretAccessKey,
      sessionToken
    })
    this.mqttClient = mqttClient

    mqttClient.on('connect', () => {
      mqttClient.subscribe(constant.mqtt.announceTopic)
    })
    this._listenMessage()

    this.didSetMqttClient = true

    setInterval(async () => {
      await this._updateToken()
    }, 1000 * 60 * 59) // 1hぴったりだと切れる可能性があるため
  }

  async listenChatSession({
    userObjectId,
    sessionId,
    sessionKey,
    onRecieved
  }: {
    userObjectId: string
    sessionId: string
    sessionKey: string
    onRecieved: Function
  }): Promise<void> {
    if (!this.didSetMqttClient) {
      await this.initializeMqttClient(userObjectId)
    }
    const topicName = `${constant.mqtt.messageTopicPrefix}/${sessionId}`
    this.mqttClient.subscribe(topicName)
    this.currentSubscribeTopicName = topicName
    this.sessionId = sessionId
    this.sessionCallback = onRecieved
    this.sessionKey = sessionKey
  }

  removeListerChatSession(): void {
    this.mqttClient.unsubscribe(this.currentSubscribeTopicName)
    this.sessionId = undefined
    this.sessionCallback = undefined
  }

  async fetchRooms(): Promise<any> {
    const rooms = await this.get('/api/chat/rooms')

    return rooms.map((room: any) => {
      if (!room.latest) {
        return { ...room, members: room.members.filter((item: any) => item) }
      }

      const latestMessage = decryptMessage(room.latest.message, room.key)
      return {
        ...room,
        members: room.members.filter((item: any) => item),
        latest: {
          ...room.latest,
          message: latestMessage
        }
      }
    })
  }

  async fetchRoom(
    sessionId: string,
    params: { limit: number | undefined; offset: number | undefined } = {
      limit: 500,
      offset: 0
    }
  ): Promise<any> {
    const room = await this.get(`/api/chat/rooms/${sessionId}`, { params })
    const messages = room.messages || []
    return {
      ...room,
      members: room.members.filter((item: any) => item),
      messages: messages.map((message: any) => ({
        ...message,
        message: decryptMessage(message.message, room.key),
        medias: (message.medias || []).map((media: any) => ({
          ...media,
          name: decryptMessage(media.name, room.key)
        }))
      }))
    }
  }

  async fetchRoomDetail(sessionId: string): Promise<any> {
    const room = await this.get(`/api/chat/rooms/${sessionId}/members`)
    return {
      ...room,
      members: room.members.filter((item: any) => item)
    }
  }

  createRoom({
    memberList,
    title
  }: {
    memberList: string[]
    title: string
  }): Promise<any> {
    return this.post('/api/chat/rooms', {
      memberList,
      title
    })
  }

  sendMessage(
    sessionId: string,
    {
      message,
      sessionKey,
      medias
    }: { message: string; sessionKey: string; medias: [] }
  ): Promise<any> {
    const encryptedMedias = medias.map((file: any) => {
      return {
        ...file,
        original: file.original,
        thumbnail: file.thumbnail,
        name: encryptMessage(file.name, sessionKey)
      }
    })
    return this.post(`/api/chat/rooms/${sessionId}/messages`, {
      message: message ? encryptMessage(message, sessionKey) : undefined,
      sessionKey,
      medias: encryptedMedias
    })
  }

  getUnreadCount(): Promise<any> {
    return this.get('/api/chat/unreadCount')
  }

  readMessages(sessionId: string): Promise<any> {
    return this.post(`/api/chat/rooms/${sessionId}/readMessages`)
  }

  async _decryptMqttPayload(
    payload: AnalysisErrorType,
    sessionKey: string
  ): Promise<any> {
    const data = await this.post('/api/chat/messages/decrypt', {
      payload,
      sessionKey
    })
    return {
      ...data,
      message: data.message
        ? decryptMessage(data.message, sessionKey)
        : undefined,
      medias: (data.medias || []).map((media: any) => ({
        ...media,
        name: decryptMessage(media.name, sessionKey)
      }))
    }
  }

  async getMediaBinary(
    sessionId: string,
    imageObjectId: string,
    sessionKey: string
  ): Promise<any> {
    const result = await this.get(
      `/api/chat/rooms/${sessionId}/medias/${encodeURIComponent(imageObjectId)}`
    )
    return decryptMessage(result.data, sessionKey)
  }

  findChatDoctorHistory(): Promise<any> {
    return this.get(`/api/chat/history`)
  }

  updateRoom({
    title,
    doctorObjectIds,
    sessionId
  }: {
    title: string
    doctorObjectIds: string[]
    sessionId: string
  }): Promise<any> {
    return this.patch(`/api/chat/rooms/${sessionId}`, {
      title,
      memberList: doctorObjectIds
    })
  }

  _getCert(): Promise<any> {
    return this.get('/api/chat/cert')
  }

  _listenMessage(): void {
    this.mqttClient.on('message', async (topic: string, payload: any) => {
      if (topic === constant.mqtt.announceTopic) {
        // 必要に応じて追加する
      }
      if (topic === `${constant.mqtt.messageTopicPrefix}/${this.sessionId}`) {
        if (this.sessionCallback) {
          const data = JSON.parse(payload.toString())
          const decryptData = await this._decryptMqttPayload(
            data,
            this.sessionKey
          )
          this.sessionCallback(decryptData)
        }
      }
    })
  }

  async _updateToken(): Promise<any> {
    const { accessKeyId, secretAccessKey, sessionToken } = await this._getCert()
    this.mqttClient.updateWebSocketCredentials(
      accessKeyId,
      secretAccessKey,
      sessionToken
    )
  }
}
