import HttpRepository from './httpRepository'

export default class ContactRepository extends HttpRepository {
  public sendCoMedicalInvitationEmail(params: {
    hospitalObjectId: string
    isSendMe: boolean
    toEmail: string
  }): Promise<void> {
    return this.post('/api/invitation', params)
  }
}
