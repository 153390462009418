<template lang="pug">
div
  v-app.app-container
    v-content.main-container
      nuxt
      snack-bar-container
</template>

<script>
export default {
  components: {
    SnackBarContainer: () =>
      import('~/components/sp/common/SnackBarContainer.vue')
  },
  middleware: 'authCheck'
}
</script>

<style lang="scss" scoped>
.main-container {
  @media screen and (min-width: 1040px) {
    margin: 0 auto;
    width: 1040px;
  }
}
.app-container {
  background-color: white;
}
</style>
