export const state = () => ({
  unreadCountTotal: 0,
  unreadCountDoctor: 0,
  unreadCountMs: 0,
  unreadRequestCountTotal: 0,
  unreadRequestCountDoctor: 0,
  unreadRequestCountMs: 0,
  beforeUpdateUnreadCountUnixTime: undefined,
  beforeUpdateUnreadCountUnixTimeRequest: undefined
})

export const getters = {
  unreadBusinessCardCountTotal: state => state.unreadCountTotal,
  unreadBusinessCardCountDoctor: state => state.unreadCountDoctor,
  unreadBusinessCardCountMs: state => state.unreadCountMs,
  unreadBusinessCardRequestCountTotal: state => state.unreadRequestCountTotal,
  unreadBusinessCardRequestCountDoctor: state => state.unreadRequestCountDoctor,
  unreadBusinessCardRequestCountMs: state => state.unreadRequestCountMs
}

export const mutations = {
  setUnreadCount(state, { count, doctor, ms, updateTime }) {
    state.unreadCountTotal = count
    state.unreadCountDoctor = doctor
    state.unreadCountMs = ms
    state.beforeUpdateUnreadCountUnixTime = updateTime
  },
  setRequestUnreadCount(state, { count, doctor, ms, updateTime }) {
    state.unreadRequestCountTotal = count
    state.unreadRequestCountDoctor = doctor
    state.unreadRequestCountMs = ms
    state.beforeUpdateUnreadCountUnixTimeRequest = updateTime
  }
}

export const actions = {
  async updateUnreadCount({ commit }) {
    const { count, doctor, ms } = await this.$axios.$get(
      '/api/businessCard/getBusinessCardUnreadCount'
    )
    commit('setUnreadCount', {
      count,
      doctor,
      ms,
      updateTime: Date.now()
    })
  },
  async updateRequestUnreadCount({ commit }) {
    const { count, doctor, ms } = await this.$axios.$get(
      '/api/businessCard/getBusinessCardRequestCount'
    )
    commit('setRequestUnreadCount', {
      count,
      doctor,
      ms,
      updateTime: Date.now()
    })
  }
}
