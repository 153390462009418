<template>
  <VSnackbar
    class="snackbar"
    :value="!!toast.message"
    color="rgba(44, 44, 44, 0.85)"
    top
  >
    <VRow class="justify-center">
      <Icon
        v-show="isSuccess"
        class="icon"
        color="white"
        size="12px"
        type="check"
      />
      <span class="text">{{ toast.message }}</span>
    </VRow>
  </VSnackbar>
</template>

<script>
import { mapState } from 'vuex'
import Icon from '~/components/commons/atoms/Icon.vue'

export default {
  components: {
    Icon
  },
  computed: {
    ...mapState(['toast']),
    isSuccess() {
      return this.toast?.type === 'success' || false
    }
  }
}
</script>

<style scoped lang="scss">
.snackbar::v-deep {
  .v-snack__wrapper {
    box-shadow: none;
  }
}
.text {
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 0.03em;
  text-align: center;
}
.icon {
  margin-right: 10px;
}
</style>
