import HttpRepository from './httpRepository'

export default class NotificationRepository extends HttpRepository {
  read(id: string): Promise<void> {
    return this.post(`/api/notification/${id}/read`)
  }

  fetchDetail(id: string): Promise<any> {
    return this.get(`/api/notification/${id}`)
  }

  fetchList(limit: number, offset: number): Promise<any> {
    const params: Partial<{ limit: number; offset: number }> = {}
    if (limit) {
      params.limit = limit
    }

    if (offset) {
      params.offset = offset
    }

    return this.get(`/api/notifications`, { params })
  }
}
