export const state = () => ({})

export const mutations = {
  set_paper(state, paper) {
    state.paper = paper
  }
}

export const actions = {
  async get_paper({ commit }, { id }) {
    const { data } = await this.$axios.get(`/api/papers/${id}`)
    commit('set_paper', data.hits.hits[0]._source)
  }
}
