import DoctorRepository from './repository/doctorRepository'
import HospitalRepository from './repository/hospitalRepository'
import ListRepository from './repository/listRepository'
import SearchRepository from './repository/searchRepository'
import FileRepository from './repository/fileRepository'
import HospitalNoticeRepository from './repository/hospitalNoticeRepository'
import ChatRepository from './repository/chatRepository'
import AuthRepository from './repository/authRepository'
import UserRepository from './repository/userRepository'
import NotificationRepository from './repository/notificationRepository'
import GroupRepository from './repository/groupRepository'
import GroupNoticeRepository from './repository/groupNoticeRepository'
import ContactRepository from './repository/contactRepository'
import MsRepository from './repository/msRepository'
import BusinessCardRepository from './repository/businessCardRepository'
import MockChatRepository from './repository/mock/mockChatRepository'

export default (context, inject) => {
  const authRepository = new AuthRepository(context.$axios)
  context.$authRepository = authRepository
  inject('authRepository', authRepository)

  const userRepository = new UserRepository(context.$axios)
  context.$userRepository = userRepository
  inject('userRepository', userRepository)

  const doctorRepository = new DoctorRepository(context.$axios)
  context.$doctorRepository = doctorRepository
  inject('doctorRepository', doctorRepository)

  const hospitalRepository = new HospitalRepository(context.$axios)
  context.$hospitalRepository = hospitalRepository
  inject('hospitalRepository', hospitalRepository)

  const listRepository = new ListRepository(context.$axios)
  context.$listRepository = listRepository
  inject('listRepository', listRepository)

  const searchRepository = new SearchRepository(context.$axios)
  context.$searchRepository = searchRepository
  inject('searchRepository', searchRepository)

  const fileRepository = new FileRepository(context.$axios)
  context.$fileRepository = fileRepository
  inject('fileRepository', fileRepository)

  const hospitalNoticeRepository = new HospitalNoticeRepository(context.$axios)
  context.$hospitalNoticeRepository = hospitalNoticeRepository
  inject('hospitalNoticeRepository', hospitalNoticeRepository)

  let chatRepository
  if (process.env.STAGE === 'mock') {
    chatRepository = new MockChatRepository()
  } else {
    chatRepository = new ChatRepository(context.$axios)
  }
  context.$chatRepository = chatRepository
  inject('chatRepository', chatRepository)

  const notificationRepository = new NotificationRepository(context.$axios)
  context.$notificationRepository = notificationRepository
  inject('notificationRepository', notificationRepository)

  const groupRepository = new GroupRepository(context.$axios)
  context.$groupRepository = groupRepository
  inject('groupRepository', groupRepository)

  const groupNoticeRepository = new GroupNoticeRepository(context.$axios)
  context.$groupNoticeRepository = groupNoticeRepository
  inject('groupNoticeRepository', groupNoticeRepository)

  const contactRepository = new ContactRepository(context.$axios)
  context.$contactRepository = contactRepository
  inject('contactRepository', contactRepository)

  const msRepository = new MsRepository(context.$axios)
  context.$msRepository = msRepository
  inject('msRepository', msRepository)

  const businessCardRepository = new BusinessCardRepository(context.$axios)
  context.$businessCardRepository = businessCardRepository
  inject('businessCardRepository', businessCardRepository)
}
