import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _07ce18e0 = () => interopDefault(import('../pages/add-home.vue' /* webpackChunkName: "pages/add-home" */))
const _0b89bf3e = () => interopDefault(import('../pages/agree-terms.vue' /* webpackChunkName: "pages/agree-terms" */))
const _4accd47b = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _52967f92 = () => interopDefault(import('../pages/doctor/index.vue' /* webpackChunkName: "pages/doctor/index" */))
const _1de15c7d = () => interopDefault(import('../pages/group/index.vue' /* webpackChunkName: "pages/group/index" */))
const _4799b577 = () => interopDefault(import('../pages/hospitals/index.vue' /* webpackChunkName: "pages/hospitals/index" */))
const _3cfea09e = () => interopDefault(import('../pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _09edcd64 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3ec73653 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _38d04a4e = () => interopDefault(import('../pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _c504b17a = () => interopDefault(import('../pages/notification/index.vue' /* webpackChunkName: "pages/notification/index" */))
const _f8f8a120 = () => interopDefault(import('../pages/passwordSetting.vue' /* webpackChunkName: "pages/passwordSetting" */))
const _068bfd6a = () => interopDefault(import('../pages/posting_information.vue' /* webpackChunkName: "pages/posting_information" */))
const _40dcdae4 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _cf8dce04 = () => interopDefault(import('../pages/regist-confirm.vue' /* webpackChunkName: "pages/regist-confirm" */))
const _f4aedb86 = () => interopDefault(import('../pages/retry-register.vue' /* webpackChunkName: "pages/retry-register" */))
const _54530494 = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _743df8bd = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _ffb467a6 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _2b782efc = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _38212c74 = () => interopDefault(import('../pages/unreceived_auth_code.vue' /* webpackChunkName: "pages/unreceived_auth_code" */))
const _360c681d = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _eb332ed4 = () => interopDefault(import('../pages/business-card/holder/index.vue' /* webpackChunkName: "pages/business-card/holder/index" */))
const _25747624 = () => interopDefault(import('../pages/business-card/requests/index.vue' /* webpackChunkName: "pages/business-card/requests/index" */))
const _6e3b9d1d = () => interopDefault(import('../pages/contact/complete.vue' /* webpackChunkName: "pages/contact/complete" */))
const _63075be8 = () => interopDefault(import('../pages/contact/confirm.vue' /* webpackChunkName: "pages/contact/confirm" */))
const _3d72fdb0 = () => interopDefault(import('../pages/contact/form.vue' /* webpackChunkName: "pages/contact/form" */))
const _5014f808 = () => interopDefault(import('../pages/doctor/new/index.vue' /* webpackChunkName: "pages/doctor/new/index" */))
const _a8bbbfda = () => interopDefault(import('../pages/doctor/search.vue' /* webpackChunkName: "pages/doctor/search" */))
const _1c47a1ea = () => interopDefault(import('../pages/group/new.vue' /* webpackChunkName: "pages/group/new" */))
const _06bc1db8 = () => interopDefault(import('../pages/hospitals/edit/index.vue' /* webpackChunkName: "pages/hospitals/edit/index" */))
const _575bd2d3 = () => interopDefault(import('../pages/hospitals/search.vue' /* webpackChunkName: "pages/hospitals/search" */))
const _55b78eac = () => interopDefault(import('../pages/invitation/complete.vue' /* webpackChunkName: "pages/invitation/complete" */))
const _362ef1bd = () => interopDefault(import('../pages/lists/hospitals/index.vue' /* webpackChunkName: "pages/lists/hospitals/index" */))
const _71432621 = () => interopDefault(import('../pages/lists/new.vue' /* webpackChunkName: "pages/lists/new" */))
const _6525b206 = () => interopDefault(import('../pages/medical/confirm.vue' /* webpackChunkName: "pages/medical/confirm" */))
const _7905a534 = () => interopDefault(import('../pages/medical/profile.vue' /* webpackChunkName: "pages/medical/profile" */))
const _52d0216b = () => interopDefault(import('../pages/medical/signup.vue' /* webpackChunkName: "pages/medical/signup" */))
const _9fe44622 = () => interopDefault(import('../pages/migration/complete.vue' /* webpackChunkName: "pages/migration/complete" */))
const _3a7de390 = () => interopDefault(import('../pages/migration/registered.vue' /* webpackChunkName: "pages/migration/registered" */))
const _d2edcfa2 = () => interopDefault(import('../pages/ms/search/index.vue' /* webpackChunkName: "pages/ms/search/index" */))
const _45de6d77 = () => interopDefault(import('../pages/mypage/search.vue' /* webpackChunkName: "pages/mypage/search" */))
const _aa741cac = () => interopDefault(import('../pages/notices/new/index.vue' /* webpackChunkName: "pages/notices/new/index" */))
const _34ea4a84 = () => interopDefault(import('../pages/rooms/new.vue' /* webpackChunkName: "pages/rooms/new" */))
const _09cf9998 = () => interopDefault(import('../pages/setting/mail_magazine.vue' /* webpackChunkName: "pages/setting/mail_magazine" */))
const _fd1ef4d6 = () => interopDefault(import('../pages/top/medical/index.vue' /* webpackChunkName: "pages/top/medical/index" */))
const _fa615096 = () => interopDefault(import('../pages/top/ms.vue' /* webpackChunkName: "pages/top/ms" */))
const _bbc1c114 = () => interopDefault(import('../pages/doctor/new/confirm.vue' /* webpackChunkName: "pages/doctor/new/confirm" */))
const _304cedbc = () => interopDefault(import('../pages/doctor/new/profile/index.vue' /* webpackChunkName: "pages/doctor/new/profile/index" */))
const _25ede7b6 = () => interopDefault(import('../pages/doctor/new/select.vue' /* webpackChunkName: "pages/doctor/new/select" */))
const _387f9a11 = () => interopDefault(import('../pages/group/member/add.vue' /* webpackChunkName: "pages/group/member/add" */))
const _80f50532 = () => interopDefault(import('../pages/hospitals/edit/bed.vue' /* webpackChunkName: "pages/hospitals/edit/bed" */))
const _64b0b2d0 = () => interopDefault(import('../pages/hospitals/edit/medical_staff.vue' /* webpackChunkName: "pages/hospitals/edit/medical_staff" */))
const _40ad8199 = () => interopDefault(import('../pages/hospitals/edit/open_bed/index.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/index" */))
const _74a62e44 = () => interopDefault(import('../pages/hospitals/edit/outpatient/index.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/index" */))
const _3bef4832 = () => interopDefault(import('../pages/hospitals/edit/patient_date.vue' /* webpackChunkName: "pages/hospitals/edit/patient_date" */))
const _f66cdab2 = () => interopDefault(import('../pages/hospitals/edit/result.vue' /* webpackChunkName: "pages/hospitals/edit/result" */))
const _46c70a68 = () => interopDefault(import('../pages/lists/hospitals/add.vue' /* webpackChunkName: "pages/lists/hospitals/add" */))
const _215c2254 = () => interopDefault(import('../pages/notices/new/select.vue' /* webpackChunkName: "pages/notices/new/select" */))
const _b90b6cb2 = () => interopDefault(import('../pages/doctor/new/profile/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/new/profile/doctorExpertCategory" */))
const _ee4f9d32 = () => interopDefault(import('../pages/doctor/new/profile/pending.vue' /* webpackChunkName: "pages/doctor/new/profile/pending" */))
const _77f3b8df = () => interopDefault(import('../pages/doctor/new/profile/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/searchPaperMeshList" */))
const _b833fee0 = () => interopDefault(import('../pages/doctor/new/profile/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/surgeryMeshList" */))
const _551df0e6 = () => interopDefault(import('../pages/doctor/new/profile/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/unacceptableMeshList" */))
const _43b51c88 = () => interopDefault(import('../pages/hospitals/edit/outpatient/edit.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/edit" */))
const _3e172cef = () => interopDefault(import('../pages/doctor/new/profile/editHospital/_id.vue' /* webpackChunkName: "pages/doctor/new/profile/editHospital/_id" */))
const _78b11641 = () => interopDefault(import('../pages/hospitals/edit/open_bed/_id.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/_id" */))
const _2f10856b = () => interopDefault(import('../pages/group/notice/_id/index.vue' /* webpackChunkName: "pages/group/notice/_id/index" */))
const _7b583f65 = () => interopDefault(import('../pages/lists/hospitals/_id.vue' /* webpackChunkName: "pages/lists/hospitals/_id" */))
const _6834e7a2 = () => interopDefault(import('../pages/doctor/_id/index.vue' /* webpackChunkName: "pages/doctor/_id/index" */))
const _58e41e30 = () => interopDefault(import('../pages/group/_id/index.vue' /* webpackChunkName: "pages/group/_id/index" */))
const _5b96acc2 = () => interopDefault(import('../pages/hospitals/_id.vue' /* webpackChunkName: "pages/hospitals/_id" */))
const _1828ea59 = () => interopDefault(import('../pages/invitation/_id.vue' /* webpackChunkName: "pages/invitation/_id" */))
const _67cb1269 = () => interopDefault(import('../pages/ms/_id/index.vue' /* webpackChunkName: "pages/ms/_id/index" */))
const _7a343d78 = () => interopDefault(import('../pages/notices/_id/index.vue' /* webpackChunkName: "pages/notices/_id/index" */))
const _53c29e6b = () => interopDefault(import('../pages/notification/_id.vue' /* webpackChunkName: "pages/notification/_id" */))
const _f5e3a8b2 = () => interopDefault(import('../pages/papers/_id.vue' /* webpackChunkName: "pages/papers/_id" */))
const _5d2dcda1 = () => interopDefault(import('../pages/rooms/_id/index.vue' /* webpackChunkName: "pages/rooms/_id/index" */))
const _aa9ee326 = () => interopDefault(import('../pages/doctor/_id/edit/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/index" */))
const _15387564 = () => interopDefault(import('../pages/group/_id/edit.vue' /* webpackChunkName: "pages/group/_id/edit" */))
const _2bb1542a = () => interopDefault(import('../pages/group/_id/history.vue' /* webpackChunkName: "pages/group/_id/history" */))
const _e0abfe12 = () => interopDefault(import('../pages/group/_id/member/index.vue' /* webpackChunkName: "pages/group/_id/member/index" */))
const _100deff4 = () => interopDefault(import('../pages/ms/_id/edit/index.vue' /* webpackChunkName: "pages/ms/_id/edit/index" */))
const _79244a0b = () => interopDefault(import('../pages/notices/_id/edit/index.vue' /* webpackChunkName: "pages/notices/_id/edit/index" */))
const _6c3d9d8b = () => interopDefault(import('../pages/rooms/_id/edit.vue' /* webpackChunkName: "pages/rooms/_id/edit" */))
const _3d812daa = () => interopDefault(import('../pages/rooms/_id/members/index.vue' /* webpackChunkName: "pages/rooms/_id/members/index" */))
const _60ad31b3 = () => interopDefault(import('../pages/doctor/_id/edit/category.vue' /* webpackChunkName: "pages/doctor/_id/edit/category" */))
const _3256b608 = () => interopDefault(import('../pages/doctor/_id/edit/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/_id/edit/doctorExpertCategory" */))
const _59353c37 = () => interopDefault(import('../pages/doctor/_id/edit/email.vue' /* webpackChunkName: "pages/doctor/_id/edit/email" */))
const _f3c4e542 = () => interopDefault(import('../pages/doctor/_id/edit/papers/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/index" */))
const _da33c22c = () => interopDefault(import('../pages/doctor/_id/edit/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/searchPaperMeshList" */))
const _b23889ce = () => interopDefault(import('../pages/doctor/_id/edit/specialty.vue' /* webpackChunkName: "pages/doctor/_id/edit/specialty" */))
const _1c7b241b = () => interopDefault(import('../pages/doctor/_id/edit/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/surgeryMeshList" */))
const _cf0f678a = () => interopDefault(import('../pages/doctor/_id/edit/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/unacceptableMeshList" */))
const _2fa39843 = () => interopDefault(import('../pages/group/_id/notice/new.vue' /* webpackChunkName: "pages/group/_id/notice/new" */))
const _1ec8d613 = () => interopDefault(import('../pages/notices/_id/edit/select.vue' /* webpackChunkName: "pages/notices/_id/edit/select" */))
const _0ac0148c = () => interopDefault(import('../pages/rooms/_id/members/add.vue' /* webpackChunkName: "pages/rooms/_id/members/add" */))
const _7cd95e6e = () => interopDefault(import('../pages/doctor/_id/edit/papers/delete.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/delete" */))
const _22f7b35e = () => interopDefault(import('../pages/doctor/_id/edit/hospitals/_index.vue' /* webpackChunkName: "pages/doctor/_id/edit/hospitals/_index" */))
const _3dc7e0ef = () => interopDefault(import('../pages/rooms/_id/medias/_mediaId.vue' /* webpackChunkName: "pages/rooms/_id/medias/_mediaId" */))
const _40462a4d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add-home",
    component: _07ce18e0,
    name: "add-home"
  }, {
    path: "/agree-terms",
    component: _0b89bf3e,
    name: "agree-terms"
  }, {
    path: "/confirm",
    component: _4accd47b,
    name: "confirm"
  }, {
    path: "/doctor",
    component: _52967f92,
    name: "doctor"
  }, {
    path: "/group",
    component: _1de15c7d,
    name: "group"
  }, {
    path: "/hospitals",
    component: _4799b577,
    name: "hospitals"
  }, {
    path: "/invitation",
    component: _3cfea09e,
    name: "invitation"
  }, {
    path: "/login",
    component: _09edcd64,
    name: "login"
  }, {
    path: "/mypage",
    component: _3ec73653,
    name: "mypage"
  }, {
    path: "/notices",
    component: _38d04a4e,
    name: "notices"
  }, {
    path: "/notification",
    component: _c504b17a,
    name: "notification"
  }, {
    path: "/passwordSetting",
    component: _f8f8a120,
    name: "passwordSetting"
  }, {
    path: "/posting_information",
    component: _068bfd6a,
    name: "posting_information"
  }, {
    path: "/profile",
    component: _40dcdae4,
    name: "profile"
  }, {
    path: "/regist-confirm",
    component: _cf8dce04,
    name: "regist-confirm"
  }, {
    path: "/retry-register",
    component: _f4aedb86,
    name: "retry-register"
  }, {
    path: "/rooms",
    component: _54530494,
    name: "rooms"
  }, {
    path: "/search",
    component: _743df8bd,
    name: "search"
  }, {
    path: "/signup",
    component: _ffb467a6,
    name: "signup"
  }, {
    path: "/terms",
    component: _2b782efc,
    name: "terms"
  }, {
    path: "/unreceived_auth_code",
    component: _38212c74,
    name: "unreceived_auth_code"
  }, {
    path: "/welcome",
    component: _360c681d,
    name: "welcome"
  }, {
    path: "/business-card/holder",
    component: _eb332ed4,
    name: "business-card-holder"
  }, {
    path: "/business-card/requests",
    component: _25747624,
    name: "business-card-requests"
  }, {
    path: "/contact/complete",
    component: _6e3b9d1d,
    name: "contact-complete"
  }, {
    path: "/contact/confirm",
    component: _63075be8,
    name: "contact-confirm"
  }, {
    path: "/contact/form",
    component: _3d72fdb0,
    name: "contact-form"
  }, {
    path: "/doctor/new",
    component: _5014f808,
    name: "doctor-new"
  }, {
    path: "/doctor/search",
    component: _a8bbbfda,
    name: "doctor-search"
  }, {
    path: "/group/new",
    component: _1c47a1ea,
    name: "group-new"
  }, {
    path: "/hospitals/edit",
    component: _06bc1db8,
    name: "hospitals-edit"
  }, {
    path: "/hospitals/search",
    component: _575bd2d3,
    name: "hospitals-search"
  }, {
    path: "/invitation/complete",
    component: _55b78eac,
    name: "invitation-complete"
  }, {
    path: "/lists/hospitals",
    component: _362ef1bd,
    name: "lists-hospitals"
  }, {
    path: "/lists/new",
    component: _71432621,
    name: "lists-new"
  }, {
    path: "/medical/confirm",
    component: _6525b206,
    name: "medical-confirm"
  }, {
    path: "/medical/profile",
    component: _7905a534,
    name: "medical-profile"
  }, {
    path: "/medical/signup",
    component: _52d0216b,
    name: "medical-signup"
  }, {
    path: "/migration/complete",
    component: _9fe44622,
    name: "migration-complete"
  }, {
    path: "/migration/registered",
    component: _3a7de390,
    name: "migration-registered"
  }, {
    path: "/ms/search",
    component: _d2edcfa2,
    name: "ms-search"
  }, {
    path: "/mypage/search",
    component: _45de6d77,
    name: "mypage-search"
  }, {
    path: "/notices/new",
    component: _aa741cac,
    name: "notices-new"
  }, {
    path: "/rooms/new",
    component: _34ea4a84,
    name: "rooms-new"
  }, {
    path: "/setting/mail_magazine",
    component: _09cf9998,
    name: "setting-mail_magazine"
  }, {
    path: "/top/medical",
    component: _fd1ef4d6,
    name: "top-medical"
  }, {
    path: "/top/ms",
    component: _fa615096,
    name: "top-ms"
  }, {
    path: "/doctor/new/confirm",
    component: _bbc1c114,
    name: "doctor-new-confirm"
  }, {
    path: "/doctor/new/profile",
    component: _304cedbc,
    name: "doctor-new-profile"
  }, {
    path: "/doctor/new/select",
    component: _25ede7b6,
    name: "doctor-new-select"
  }, {
    path: "/group/member/add",
    component: _387f9a11,
    name: "group-member-add"
  }, {
    path: "/hospitals/edit/bed",
    component: _80f50532,
    name: "hospitals-edit-bed"
  }, {
    path: "/hospitals/edit/medical_staff",
    component: _64b0b2d0,
    name: "hospitals-edit-medical_staff"
  }, {
    path: "/hospitals/edit/open_bed",
    component: _40ad8199,
    name: "hospitals-edit-open_bed"
  }, {
    path: "/hospitals/edit/outpatient",
    component: _74a62e44,
    name: "hospitals-edit-outpatient"
  }, {
    path: "/hospitals/edit/patient_date",
    component: _3bef4832,
    name: "hospitals-edit-patient_date"
  }, {
    path: "/hospitals/edit/result",
    component: _f66cdab2,
    name: "hospitals-edit-result"
  }, {
    path: "/lists/hospitals/add",
    component: _46c70a68,
    name: "lists-hospitals-add"
  }, {
    path: "/notices/new/select",
    component: _215c2254,
    name: "notices-new-select"
  }, {
    path: "/doctor/new/profile/doctorExpertCategory",
    component: _b90b6cb2,
    name: "doctor-new-profile-doctorExpertCategory"
  }, {
    path: "/doctor/new/profile/pending",
    component: _ee4f9d32,
    name: "doctor-new-profile-pending"
  }, {
    path: "/doctor/new/profile/searchPaperMeshList",
    component: _77f3b8df,
    name: "doctor-new-profile-searchPaperMeshList"
  }, {
    path: "/doctor/new/profile/surgeryMeshList",
    component: _b833fee0,
    name: "doctor-new-profile-surgeryMeshList"
  }, {
    path: "/doctor/new/profile/unacceptableMeshList",
    component: _551df0e6,
    name: "doctor-new-profile-unacceptableMeshList"
  }, {
    path: "/hospitals/edit/outpatient/edit",
    component: _43b51c88,
    name: "hospitals-edit-outpatient-edit"
  }, {
    path: "/doctor/new/profile/editHospital/:id?",
    component: _3e172cef,
    name: "doctor-new-profile-editHospital-id"
  }, {
    path: "/hospitals/edit/open_bed/:id",
    component: _78b11641,
    name: "hospitals-edit-open_bed-id"
  }, {
    path: "/group/notice/:id",
    component: _2f10856b,
    name: "group-notice-id"
  }, {
    path: "/lists/hospitals/:id",
    component: _7b583f65,
    name: "lists-hospitals-id"
  }, {
    path: "/doctor/:id",
    component: _6834e7a2,
    name: "doctor-id"
  }, {
    path: "/group/:id",
    component: _58e41e30,
    name: "group-id"
  }, {
    path: "/hospitals/:id",
    component: _5b96acc2,
    name: "hospitals-id"
  }, {
    path: "/invitation/:id",
    component: _1828ea59,
    name: "invitation-id"
  }, {
    path: "/ms/:id",
    component: _67cb1269,
    name: "ms-id"
  }, {
    path: "/notices/:id",
    component: _7a343d78,
    name: "notices-id"
  }, {
    path: "/notification/:id",
    component: _53c29e6b,
    name: "notification-id"
  }, {
    path: "/papers/:id?",
    component: _f5e3a8b2,
    name: "papers-id"
  }, {
    path: "/rooms/:id",
    component: _5d2dcda1,
    name: "rooms-id"
  }, {
    path: "/doctor/:id/edit",
    component: _aa9ee326,
    name: "doctor-id-edit"
  }, {
    path: "/group/:id/edit",
    component: _15387564,
    name: "group-id-edit"
  }, {
    path: "/group/:id/history",
    component: _2bb1542a,
    name: "group-id-history"
  }, {
    path: "/group/:id/member",
    component: _e0abfe12,
    name: "group-id-member"
  }, {
    path: "/ms/:id?/edit",
    component: _100deff4,
    name: "ms-id-edit"
  }, {
    path: "/notices/:id/edit",
    component: _79244a0b,
    name: "notices-id-edit"
  }, {
    path: "/rooms/:id/edit",
    component: _6c3d9d8b,
    name: "rooms-id-edit"
  }, {
    path: "/rooms/:id/members",
    component: _3d812daa,
    name: "rooms-id-members"
  }, {
    path: "/doctor/:id/edit/category",
    component: _60ad31b3,
    name: "doctor-id-edit-category"
  }, {
    path: "/doctor/:id/edit/doctorExpertCategory",
    component: _3256b608,
    name: "doctor-id-edit-doctorExpertCategory"
  }, {
    path: "/doctor/:id/edit/email",
    component: _59353c37,
    name: "doctor-id-edit-email"
  }, {
    path: "/doctor/:id/edit/papers",
    component: _f3c4e542,
    name: "doctor-id-edit-papers"
  }, {
    path: "/doctor/:id/edit/searchPaperMeshList",
    component: _da33c22c,
    name: "doctor-id-edit-searchPaperMeshList"
  }, {
    path: "/doctor/:id/edit/specialty",
    component: _b23889ce,
    name: "doctor-id-edit-specialty"
  }, {
    path: "/doctor/:id/edit/surgeryMeshList",
    component: _1c7b241b,
    name: "doctor-id-edit-surgeryMeshList"
  }, {
    path: "/doctor/:id/edit/unacceptableMeshList",
    component: _cf0f678a,
    name: "doctor-id-edit-unacceptableMeshList"
  }, {
    path: "/group/:id/notice/new",
    component: _2fa39843,
    name: "group-id-notice-new"
  }, {
    path: "/notices/:id/edit/select",
    component: _1ec8d613,
    name: "notices-id-edit-select"
  }, {
    path: "/rooms/:id/members/add",
    component: _0ac0148c,
    name: "rooms-id-members-add"
  }, {
    path: "/doctor/:id/edit/papers/delete",
    component: _7cd95e6e,
    name: "doctor-id-edit-papers-delete"
  }, {
    path: "/doctor/:id/edit/hospitals/:index",
    component: _22f7b35e,
    name: "doctor-id-edit-hospitals"
  }, {
    path: "/rooms/:id/medias/:mediaId?",
    component: _3dc7e0ef,
    name: "rooms-id-medias-mediaId"
  }, {
    path: "/",
    component: _40462a4d,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
