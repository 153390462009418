export const state = () => ({
  doctor: null,
  editDoctor: null,
  newDoctor: {
    objectId: null,
    isConfirmCandidate: false,
    selectedDoctorObjectId: null,
    searchPaperMeshList: [],
    university: '',
    graduationYear: '',
    enrollmentPeriodList: [{ suggestions: [] }],
    alfresaCode: '',
    doctorExpertCategory: []
  },
  candidateDoctors: [],
  hospitalSuggestion: []
})

export const mutations = {
  set_doctor(state, doctor) {
    state.doctor = doctor
  },
  set_edit_doctor(state, doctor) {
    state.editDoctor = doctor
  },
  clear_edit_doctor(state) {
    state.editDoctor = null
  },
  add_edit_doctor_hospital(state) {
    state.editDoctor.hospitals.push({})
    state.editDoctor.hospitalIds.push('')
    state.editDoctor.enrollmentPeriodList.push({})
  },
  remove_edit_doctor_hospital(state, index) {
    state.editDoctor.hospitals.splice(index, 1)
    state.editDoctor.hospitalIds.splice(index, 1)
    state.editDoctor.enrollmentPeriodList.splice(index, 1)
  },
  change_edit_doctor_hospital(state, { index, hospital }) {
    state.editDoctor.hospitals[index] = hospital
    state.editDoctor.hospitalIds[index] = hospital.hospitalId
    state.editDoctor.enrollmentPeriodList[index] = {
      ...state.editDoctor.enrollmentPeriodList[index],
      hospitalId: hospital.hospitalId
    }
  },
  change_edit_doctor_university(state, params) {
    state.editDoctor.university = params
  },
  change_edit_doctor_hide_gender(state, params) {
    state.editDoctor.hideGender = params
  },
  change_edit_doctor_hide_certification_year(state, params) {
    state.editDoctor.hideCertificationYear = params
  },
  change_edit_doctor_hide_university(state, params) {
    state.editDoctor.hideUniversity = params
  },
  change_edit_doctor_hide_specialties(state, params) {
    state.editDoctor.hideSpecialties = params
  },
  change_edit_doctor_hide_papers(state, params) {
    state.editDoctor.hidePapers = params
  },
  change_edit_doctor_graduation_year(state, params) {
    state.editDoctor.graduationYear = params
  },
  add_edit_doctor_enrollmentPeriodList(state, params) {
    state.editDoctor.enrollmentPeriodList.push(params)
  },
  create_edit_doctor_enrollmentPeriodList(state, params) {
    state.editDoctor.enrollmentPeriodList = [params]
  },
  set_edit_hospital_suggestion(state, hospitals) {
    state.hospitalSuggestion = hospitals
  },
  change_edit_doctor(state, { key, value }) {
    const param = {}
    param[key] = value

    state.editDoctor = {
      ...state.editDoctor,
      ...param
    }
  },
  confirmCandidate(state, { doctor }) {
    state.newDoctor.isConfirmCandidate = true
    if (doctor) {
      state.newDoctor.enrollmentPeriodList = doctor.hospitals.map(hospital => ({
        ...hospital,
        suggestions: []
      }))
      state.newDoctor.objectId = doctor.objectId
    }
  },
  change_new_doctor(state, { key, value }) {
    const param = {}
    param[key] = value

    state.newDoctor = {
      ...state.newDoctor,
      ...param
    }
  },
  add_new_doctor_hospital(state) {
    state.newDoctor.enrollmentPeriodList.push({ suggestions: [] })
  },
  remove_new_doctor_hospital(state, index) {
    state.newDoctor.enrollmentPeriodList.splice(index, 1)
  },
  change_new_doctor_hospital(state, { index, hospital }) {
    state.newDoctor.enrollmentPeriodList.splice(index, 1, hospital)
  },
  set_new_hospital_suggestion(state, { hospitals, index }) {
    const hospital = {
      ...state.newDoctor.enrollmentPeriodList[index],
      suggestions: hospitals
    }
    state.newDoctor.enrollmentPeriodList.splice(index, 1, hospital)
  },
  set_candidate_doctors(state, doctors) {
    state.candidateDoctors = doctors
  }
}

export const actions = {
  async get_doctor({ commit }, { id }) {
    const { data } = await this.$axios.get(`/api/doctor/get?id=${id}`)
    commit('set_doctor', data)
  },
  async get_edit_doctor({ commit, rootState }) {
    const { data } = await this.$axios.get(
      `/api/doctor/getEditDoctor?id=${rootState.profile.doctorId}`
    )
    const { enrollmentPeriodList, hospitals } = data

    const findEnrollmentPeriodByHospitalId = hospitalId =>
      (enrollmentPeriodList || []).find(item => {
        return item.hospitalId === hospitalId
      })

    const editidEnrollmentPeriodList = hospitals.map(hospital => {
      const hospitalId = hospital.hospitalId
      const enrollmentPeriod =
        findEnrollmentPeriodByHospitalId(hospitalId) || {}
      return { hospitalId, ...enrollmentPeriod, isWorking: true }
    })
    const setData = {
      ...data,
      enrollmentPeriodList: editidEnrollmentPeriodList
    }
    commit('set_edit_doctor', setData)
  },
  async upload_edit_doctor_user_icon({ commit }, file) {
    const data = await this.$axios.$post('/api/images', { image: file })
    commit('change_edit_doctor', { key: 'avatarUrl', value: data.url })
  },
  clear_edit_doctor_user_icon({ commit }) {
    commit('change_edit_doctor', { key: 'avatarUrl', value: '' })
  },
  remove_edit_doctor_hospital({ commit }, index) {
    commit('remove_edit_doctor_hospital', index)
  },
  async fetch_edit_hospital_suggestion({ commit }, areaId) {
    commit('set_edit_hospital_suggestion', [])
    const data = await this.$axios.$get(`/api/hospitals/suggestions/${areaId}`)
    commit('set_edit_hospital_suggestion', data)
  },
  async update_edit_doctor({ commit }, doctor) {
    const {
      avatarUrl,
      doctorCategory,
      hospitalIds,
      introduction,
      unacceptableMeshList,
      deletedSpecialties,
      deletedPapers,
      university,
      graduationYear,
      doctorExpertCategory,
      searchPaperMeshList,
      surgeryMeshList,
      enrollmentPeriodList,
      hideGender,
      hideUniversity,
      hideCertificationYear,
      hidePapers,
      hideSpecialties
    } = doctor

    await this.$axios.$patch(`/api/doctor/${doctor.doctorId}/profile`, {
      avatarUrl,
      doctorCategory,
      hospitalIds: hospitalIds.filter(id => !!id),
      introduction,
      unacceptableMeshList,
      deletedSpecialties,
      deletedPapers,
      university,
      graduationYear,
      doctorExpertCategory,
      searchPaperMeshList,
      surgeryMeshList,
      enrollmentPeriodList: enrollmentPeriodList.map(item => ({
        ...item,
        isWorking: true
      })),
      hideGender,
      hideUniversity,
      hideCertificationYear,
      hidePapers,
      hideSpecialties
    })
    commit('clear_edit_doctor')
  },
  async create_doctor(_, doctor) {
    const {
      objectId,
      searchPaperMeshList,
      university,
      graduationYear,
      enrollmentPeriodList,
      alfresaCode,
      doctorExpertCategory
    } = doctor

    await this.$axios.$post(`/api/doctor`, {
      doctorObjectId: objectId,
      searchPaperMeshList,
      university,
      graduationYear,
      enrollmentPeriodList,
      alfresaCode,
      doctorExpertCategory
    })
  },
  async fetch_new_hospital_suggestion({ commit }, { areaId, index }) {
    commit('set_new_hospital_suggestion', { hospitals: [], index })
    const data = await this.$axios.$get(`/api/hospitals/suggestions/${areaId}`)
    commit('set_new_hospital_suggestion', { hospitals: data, index })
  },
  change_edit_doctor_hide_university({ commit }, params) {
    commit('change_edit_doctor_hide_university', params)
  },
  change_edit_doctor({ commit }, { key, value }) {
    commit('change_edit_doctor', { key, value })
  },
  change_edit_doctor_hide_certification_year({ commit }, params) {
    commit('change_edit_doctor_hide_certification_year', params)
  },
  change_edit_doctor_hide_specialties({ commit }, params) {
    commit('change_edit_doctor_hide_specialties', params)
  },
  change_edit_doctor_hide_papers({ commit }, params) {
    commit('change_edit_doctor_hide_papers', params)
  },
  change_edit_doctor_hide_gender({ commit }, params) {
    commit('change_edit_doctor_hide_gender', params)
  }
}
