import { getTopPath } from '../helper/role'
import config from '~/helper/constant'
import { TOP_PAGE_LIST } from '~/helper/enum'

export default async ({ store, redirect, app, route }) => {
  if (!store.getters.latestTermsVersion) {
    await store.dispatch('getLatestTermsOfServiceVersion')
  }
  if (TOP_PAGE_LIST.includes(route.path)) {
    return
  }
  if (!store.getters.hasAgreedTermsOfServiceVersion) {
    app.$cookies.set(config.redirectKey, route.fullPath)
    redirect(getTopPath(store.state.currentUser.role))
  }
}
