import { NuxtState } from '@nuxt/types/app'
import get from 'lodash.get'
import { Route } from 'vue-router'
import constant from '~/helper/constant'
import { User } from '~/types/api'
import { DoctorCategory } from '~/types/doctor'

export class FirebaseRepository {
  private firebaseClient: firebase.default.app.App

  constructor(firebaseClient: firebase.default.app.App) {
    this.firebaseClient = firebaseClient
  }

  setUserProperties(
    currentUser: Partial<User>,
    doctorCategory: DoctorCategory[],
    hospitalName: string,
    hospitalPrefecture: string,
    hospitalCity: string
  ) {
    const {
      objectId,
      category2,
      certificationYear,
      gender,
      route,
      role
    } = currentUser

    const subcategory1 = get(doctorCategory, '[0].subCategoryId', null)
    const subcategory2 = get(doctorCategory, '[1].subCategoryId', null)
    const subcategory3 = get(doctorCategory, '[2].subCategoryId', null)
    const subcategory4 = get(doctorCategory, '[3].subCategoryId', null)
    const subcategory5 = get(doctorCategory, '[4].subCategoryId', null)

    const analyticsData = {
      user_object_id: objectId,
      category: category2,
      certification_year: certificationYear,
      gender,
      route,
      role,
      subcategory1,
      subcategory2,
      subcategory3,
      subcategory4,
      subcategory5,
      hospital_name: hospitalName,
      hospital_prefecture: hospitalPrefecture,
      hospital_city: hospitalCity
    }

    // see https://docs.google.com/spreadsheets/d/1j3O0FlFqvOe8tOhI3LJVYdUmOzOIVnop_B8-qVXthis/edit#gid=833798215
    this.firebaseClient.analytics().setUserProperties(analyticsData)
  }

  viewEvent(to: Route, store: NuxtState) {
    if (!to || !to.name || !to.query) return

    // urlの/は-に変換される, topの/はindexになる /_idは-idになる
    let view = constant.firebaseViewEvents[to.name]

    if (to.name === 'hospitals-edit-open_bed-id' && to.params.id === 'new') {
      view = constant.firebaseViewEvents['hospitals-edit-open_bed-new']
    }

    if (!view) return

    const params: Partial<{
      mailType: string
      id: string
      source?: string
      medium?: string
    }> = {}
    if (to.query.mailType) {
      params.mailType = to.query.mailType.toString()
    }

    // _idを持っているpathであれば登録する
    if (to.params.id && to.params.id !== 'new') {
      params.id = to.params.id
    }

    // lists-doctors-add のqueryに含まれている doctorIdをログ登録に含める
    if (to.query.doctorId) {
      params.id = to.query.doctorId.toString()
    }

    // lists-hospitals-add のqueryに含まれている hospitalIdをログ登録に含める
    if (to.query.hospitalId) {
      params.id = to.query.hospitalId.toString()
    }

    if (to.name === 'hospitals-edit-open_bed-id' && to.params.id === 'new') {
      params.id = 'new'
    }

    // 下記配列に含まれるpathの際にはidがURLにないが、storeにあるhospitalIdをログ登録する
    if (
      [
        'hospitals-edit',
        'hospitals-edit-bed',
        'hospitals-edit-medical_staff',
        'hospitals-edit-open_bed',
        'hospitals-edit-outpatient',
        'hospitals-edit-outpatient-edit',
        'hospitals-edit-patient_date',
        'hospitals-edit-result'
      ].includes(to.name)
    ) {
      params.id = store.state.profile.belongTo
        ? store.state.profile.belongTo.hospitalId
        : store.state.profile.hospitals[0]?.hospitalId
    }

    if (to.query.utm_source) {
      params.source = to.query.utm_source.toString()
    }

    if (to.query.utm_medium) {
      params.medium = to.query.utm_medium.toString()
    }

    this.firebaseClient.analytics().logEvent(view, params)
  }
}
