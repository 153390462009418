<template>
  <VApp class="page-container">
    <VContent class="main-container">
      <Nuxt></Nuxt>
      <TabBarContainer />
    </VContent>
    <PortalTarget name="outside" />
    <SnackBarContainer></SnackBarContainer>
  </VApp>
</template>

<script>
import TabBarContainer from '~/components/commons/organisms/tabBar/TabBarContainer'
import SnackBarContainer from '~/components/sp/common/SnackBarContainer.vue'

export default {
  components: {
    TabBarContainer,
    SnackBarContainer
  },
  middleware: [
    'authCheck',
    'getProfile',
    'getUnreadCounts',
    'clearRefreshInterval',
    'checkTerms'
  ]
}
</script>
<style lang="scss" scoped>
.page-container {
  background-color: white;
}
.main-container::v-deep {
  .v-content__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
