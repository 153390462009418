<template>
  <div class="button-container" :style="containerStyle">
    <VBtn
      :color="colorProps"
      :outlined="outlined"
      :rounded="rounded"
      :to="to"
      :text="text"
      filled
      block
      depressed
      :ripple="ripple"
      class="button-container-button"
      @click="$emit('click', $event)"
    >
      <div :style="{ fontSize, fontWeight, width }" class="button-inner">
        <template v-if="$scopedSlots.default">
          <slot name="default" />
        </template>
        <template v-else>
          <div>{{ buttonText }}</div>
        </template>
      </div>
    </VBtn>
  </div>
</template>

<script>
import { customColorProfileKeys } from '~/helper/colors'

export default {
  props: {
    outlined: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    to: {
      type: String,
      default: '/'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '12px'
    },
    fontWeight: {
      type: String,
      default: '600'
    },
    height: {
      type: String,
      default: '32px'
    },
    width: {
      type: String,
      default: '100px'
    },
    ripple: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: 'TOPに戻る'
    }
  },
  computed: {
    colorProps() {
      const colors = [
        ...customColorProfileKeys,
        'primary',
        'accent',
        'success',
        'gray',
        'error',
        'white',
        'lightgray',
        'blue'
      ]
      if (!colors.includes(this.color)) {
        throw new Error(`想定しないcolorです。${this.color}`)
      }

      if (this.color === 'gray') {
        return '#2c2c2c'
      }

      if (this.color === 'lightgray') {
        return '#959595'
      }

      if (this.color === 'blue') {
        return '#ECEFF9'
      }

      return this.color
    },
    containerStyle() {
      return {
        height: this.height,
        width: this.width
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-container {
  .button-container-button::v-deep {
    height: 100% !important;
    padding: 0 !important;
  }
}

.button-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
