import HttpRepository from './httpRepository'

export default class GroupRepository extends HttpRepository {
  findBelongedGroups(): Promise<any> {
    return this.get('/api/groups')
  }

  getGroup(groupId: string): Promise<any> {
    return this.get(`/api/groups/${groupId}`)
  }

  createGroup(params: {
    name: string
    detail?: string
    imageUrl?: string
  }): Promise<any> | undefined {
    return this.post('/api/groups', params)
  }

  updateGroup(params: {
    communityId: string
    name: string
    detail?: string
    imageUrl?: string
  }): Promise<any> {
    return this.put(`/api/groups/${params.communityId}`, params)
  }

  deleteGroup(groupId: string): Promise<any> {
    return this.delete(`/api/groups/${groupId}`)
  }

  exitFromGroup(params: {
    communityId: string
    requestUserId: string
    requestUserRole: 'ms' | 'doctor'
  }): Promise<any> {
    return this.post(`/api/groups/${params.communityId}/exit`, params)
  }

  async updateGroupDoctors(params: {
    targetUserObjectId: string
    groupObjectIds: string[]
  }): Promise<void> {
    await this.patch(`/api/groups/doctors`, params)
  }

  checkManagerByGroup(params: {
    communityId: string
    companyCode?: string
  }): Promise<any> {
    return this.post(`/api/groups/${params.communityId}/check_manager`, params)
  }

  getDoctorsByCommunityid(groupId: string): Promise<any> {
    return this.get(`/api/groups/${groupId}/get_doctors`)
  }

  getMssByCommunityid(groupId: string): Promise<any> {
    return this.get(`/api/groups/${groupId}/get_mss`)
  }

  getGroupNotificationByGroupId(params: {
    limit: number
    offset: number
    groupId: string
  }): Promise<any> {
    return this.post(`/api/groups/${params.groupId}/get_notification`, params)
  }

  checkGroupAdmin(communityId: string): Promise<any> {
    return this.get(`/api/groups/${communityId}/check_group_admin`)
  }

  checkGroupMember(communityId: string): Promise<any> {
    return this.get(`/api/groups/${communityId}/check_group_member`)
  }

  findAdminGroups(): Promise<any> {
    return this.get('/api/groups/find_admin_groups')
  }

  findInvitedGroups(): Promise<any> {
    return this.get('/api/groups/find_invited_groups')
  }

  applyForAdmin(communityId: string): Promise<any> {
    return this.post(`/api/groups/${communityId}/apply_for_admin`)
  }

  addAdminUsers(params: {
    communityId: string
    adminUsers: string[]
  }): Promise<any> {
    return this.post(
      `/api/groups/${params.communityId}/add_admin_users`,
      params
    )
  }

  removeAdminUsers(params: {
    communityId: string
    adminUsers: string[]
  }): Promise<any> {
    return this.post(
      `/api/groups/${params.communityId}/remove_admin_users`,
      params
    )
  }

  removeUserFromGroup(params: {
    communityId: string
    userId: string
    role: string
  }): Promise<any> {
    if (params.role === 'ms') {
      return this.post(`/api/groups/${params.communityId}/remove_ms_users`, {
        communityId: params.communityId,
        msUsers: params.userId ? [params.userId] : []
      })
    }
    return this.post(`/api/groups/${params.communityId}/remove_doctor_users`, {
      communityId: params.communityId,
      doctorUsers: params.userId ? [params.userId] : []
    })
  }

  inviteGroupUsers(params: {
    communityId: string
    userIds: string[]
    message: string
    role: string
  }): Promise<any> {
    if (params.role === 'ms') {
      return this.post(`/api/groups/${params.communityId}/invite_ms_users`, {
        communityId: params.communityId,
        msUsers: params.userIds ?? [],
        message: params.message
      })
    }
    return this.post(`/api/groups/${params.communityId}/invite_doctor_users`, {
      communityId: params.communityId,
      doctorUsers: params.userIds ?? [],
      message: params.message
    })
  }

  approveGroupUsers(params: {
    communityId: string
    userIds: string[]
    role: string
  }): Promise<any> {
    if (params.role === 'ms') {
      return this.post(`/api/groups/${params.communityId}/approve_ms_users`, {
        communityId: params.communityId,
        msUsers: params.userIds ?? []
      })
    }
    return this.post(`/api/groups/${params.communityId}/approve_doctor_users`, {
      communityId: params.communityId,
      doctorUsers: params.userIds ?? []
    })
  }

  disapproveGroupUsers(params: {
    communityId: string
    userIds: string[]
    role: string
  }): Promise<any> {
    if (params.role === 'ms') {
      return this.post(
        `/api/groups/${params.communityId}/disapprove_ms_users`,
        {
          communityId: params.communityId,
          msUsers: params.userIds ?? []
        }
      )
    }
    return this.post(
      `/api/groups/${params.communityId}/disapprove_doctor_users`,
      {
        communityId: params.communityId,
        doctorUsers: params.userIds ?? []
      }
    )
  }
}
