<template>
  <section>
    <div v-if="error.statusCode === 404">
      <ErrorCommon :title="title404" :lead-text="lead404" />
    </div>
    <div v-else>
      <ErrorCommon
        :title="errorMessage"
        :lead-text="leadText"
        :show-logout-and-reload-button="isTopPage"
        @logout="logoutFunc"
        @reload="reload"
      />
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex'
import ErrorCommon from '~/components/commons/pages/error/ErrorCommon.vue'
import {
  TOP_PAGE_LIST,
  errorMessageList,
  errorMessageSingleList
} from '~/helper/enum'

export default {
  components: {
    ErrorCommon
  },
  props: {
    error: {
      required: true,
      type: Object
    }
  },
  computed: {
    errorMessage() {
      const errMessage = this.error?.message ?? ''
      switch (errMessage) {
        case errorMessageList.common.title:
          return errorMessageList.common.title
        case errorMessageList.notFound.title:
          return errorMessageList.notFound.title
        case errorMessageList.communityPermissionDenied.title:
          return errorMessageList.communityPermissionDenied.title
        case errorMessageList.notCommunityUser.title:
          return errorMessageList.notCommunityUser.title
        case errorMessageSingleList.includes(errMessage):
          return errMessage
        default:
          return errorMessageList.common.title
      }
    },
    leadText() {
      switch (this.errorMessage) {
        case errorMessageList.common.title:
          return errorMessageList.common.lead
        case errorMessageList.notFound.title:
          return errorMessageList.notFound.lead
        case errorMessageList.communityPermissionDenied.title:
          return errorMessageList.communityPermissionDenied.lead
        case errorMessageList.notCommunityUser.title:
          return errorMessageList.notCommunityUser.lead
        case errorMessageSingleList.includes(this.errorMessage):
          return ''
        default:
          return errorMessageList.systemError.lead
      }
    },
    title404() {
      return errorMessageList.common.title
    },
    lead404() {
      return errorMessageList.common.lead
    },
    isTopPage() {
      return TOP_PAGE_LIST.includes(this.$route.path)
    }
  },
  mounted() {
    const message = this.error?.message ?? ''
    // ここでとれるstatusCodeは500だったのでmessageで判定
    if (message.toString().match(/Unauthorized/)) {
      this.$nextTick(() => {
        this.jump()
      })
    }
  },
  methods: {
    ...mapActions(['logout']),
    async logoutFunc() {
      await this.logout()
      this.jump()
    },
    jump() {
      // this.$router.push だと動作しなかった
      window.location.href = '/'
    },
    reload() {
      location.reload()
    }
  }
}
</script>
