// お知らせ作成時に送信先の病院選択画面を別画面に切り出したかったのでstoreを用意
export const state = () => ({
  hospitalsStock: [],
  toHospitalObjectIds: [],
  title: '',
  detail: '',
  fileUrl: '',
  filename: '',
  hospitalNoticeUnreadCount: null
})

export const getters = {
  hospitalNoticeUnreadCount: state => state.hospitalNoticeUnreadCount ?? 0
}

export const mutations = {
  setKeyValue(state, { key, value }) {
    state[key] = value
  },
  setForm(state, payload) {
    state.hospitalsStock = payload.hospitalsStock || []
    state.toHospitalObjectIds = payload.toHospitalObjectIds || []
    state.title = payload.title || ''
    state.detail = payload.detail || ''
    state.fileUrl = payload.fileUrl || ''
    state.filename = payload.filename || ''
  },
  clearForm(state) {
    state.hospitalsStock = []
    state.toHospitalObjectIds = []
    state.title = ''
    state.detail = ''
    state.fileUrl = ''
    state.filename = ''
  },
  setHospitalNoticeUnreadCount(state, payload) {
    state.hospitalNoticeUnreadCount = payload
  }
}

export const actions = {
  setKeyValue({ commit }, payload) {
    commit('setKeyValue', payload)
  },
  setForm({ commit }, payload) {
    commit('setForm', payload)
  },
  clearForm({ commit }) {
    commit('clearForm')
  },
  async getHospitalNoticeUnreadCount({ commit }) {
    const {
      unreadCount
    } = await this.$hospitalNoticeRepository.getHospitalNoticeUnreadCount()
    commit('setHospitalNoticeUnreadCount', unreadCount)
  }
}
