import Vue from 'vue'
import constant from '~/helper/constant'

const VueGoogleMaps = require('vue2-google-maps')

Vue.use(VueGoogleMaps, {
  load: {
    key: constant.googleMapKey,
    libraries: 'places',
    region: 'JP',
    language: 'ja'
  },
  installComponents: true
})
