export default async ({ store }) => {
  if (!store.getters.isRoleDoctor) {
    return
  }
  await store
    .dispatch('hospitalNotice/getHospitalNoticeUnreadCount')
    .catch(() => {
      // 通知の取得でエラーが出てもクリティカルではないため握りつぶす
    })
}
