import _ from 'lodash'
import { SIGNUP_CONDITION } from '~/helper/enum'

const initialDoctorData = {
  hits: [],
  total: 0
}

const initialHospitalData = {
  hits: [],
  total: 0
}

const toArr = input => {
  if (Array.isArray(input)) {
    return input
  }
  if (!input) {
    return []
  }
  return [input]
}

export const state = () => ({
  showAreaTree: null,
  showCategoryTree: null,
  currentTabState: '',
  currentTabScroll: 0,
  doctor: { ...initialDoctorData },
  hospital: { ...initialHospitalData }
})

export const getters = {
  getSearchDoctorTotal(state) {
    return state.doctor.total
  }
}

export const mutations = {
  SET_currentTabState(state, data) {
    state.currentTabState = data
  },
  SET_currentTabScroll(state, data) {
    state.currentTabScroll = data
  },
  setHits(state, { key, val, offset }) {
    if (offset === 0) {
      state[key].hits = val
    } else {
      state[key].hits = [...state[key].hits, ...val]
    }
  },
  setTotal(state, { key, val }) {
    state[key].total = val
  },
  setHitsDoctor(state, { val, offset }) {
    if (offset === 0) {
      state.doctor.hits = val
    } else {
      state.doctor.hits.push(...val)
    }
  },
  setTotalDoctor(state, val) {
    state.doctor.total = val
  },
  clearSearch(state, { key }) {
    if (key === 'hospital') {
      state.hospital = { ...initialHospitalData }
    } else {
      state[key] = { ...initialDoctorData }
    }
  },
  clearSearchDoctor(state) {
    state.doctor = { ...initialDoctorData }
  }
}

export const actions = {
  setCurrentTabState({ commit }, { tab }) {
    commit('SET_currentTabState', tab)
  },

  setCurrentTabScroll({ commit }, { scroll }) {
    commit('SET_currentTabScroll', scroll)
  },

  async searchDoctor(
    { commit },
    {
      selectedAreaIds,
      selectedCategoryDisplay,
      selectedSpecialties,
      selectedDoctorExpertCategoryIds,
      searchLastName,
      searchFirstName,
      searchPartofName,
      searchHospitalName,
      signupCondition,
      offset
    }
  ) {
    const medicalAreaIds = toArr(selectedAreaIds).join(',')

    const signupConditionNumber =
      signupCondition !== undefined && signupCondition !== null
        ? Number(signupCondition)
        : SIGNUP_CONDITION.COMMON.VALUE

    const responseDoctor = await this.$axios
      .post('/api/doctor/search', {
        specialties: toArr(selectedSpecialties),
        lastName: searchLastName,
        firstName: searchFirstName,
        partofName: searchPartofName,
        hospitalName: searchHospitalName,
        departments: selectedCategoryDisplay,
        doctorExpertCategory: toArr(selectedDoctorExpertCategoryIds),
        signupCondition: signupConditionNumber,
        from: offset || 0,
        medicalAreaIds
      })
      .catch(err => {
        console.log('@@@ search.js<142>: ', err)
      })
    const hitsDoctor = _.get(responseDoctor, 'data.hits.hits', [])
    const doctorHitsTotal = _.get(responseDoctor, 'data.hits.total', 0)

    commit('setHitsDoctor', {
      val: hitsDoctor,
      offset: offset || 0
    })
    commit('setTotalDoctor', doctorHitsTotal)
  },

  clearSearchDoctor({ commit }) {
    commit('clearSearchDoctor')
  },

  async searchHospital(
    { commit },
    {
      selectedAreaIds,
      selectedCategoryIds,
      searchName,
      selectedSpecialties,
      selectedHospitalTypes,
      selectedDoctorExpertCategoryIds,
      offset
    }
  ) {
    const medicalAreaIds = toArr(selectedAreaIds).join(',')
    const responseHospital = await this.$axios
      .post('/api/hospital/search', {
        name: searchName,
        specialties: toArr(selectedSpecialties),
        hospitalTypes: toArr(selectedHospitalTypes),
        subCategoryIds: toArr(selectedCategoryIds).map(item => Number(item)),
        doctorExpertCategory: toArr(selectedDoctorExpertCategoryIds),
        medicalAreaIds,
        from: offset || 0
      })
      .catch(err => {
        console.log('@@@ search.js<151>: ', err)
        throw err
      })

    const hitsHospital = _.get(responseHospital, 'data.hits.hits', [])
    const hospitalHitsTotal = _.get(responseHospital, 'data.hits.total', 0)
    commit('setHits', {
      key: 'hospital',
      val: hitsHospital,
      offset: offset || 0
    })
    commit('setTotal', { key: 'hospital', val: hospitalHitsTotal })
  }
}
