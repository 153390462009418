import { errorMessages } from '~/helper/messages'

export default ({ $axios, redirect, store }) => {
  $axios.onResponseError(async error => {
    // parseのアップデートに伴うエラーメッセージのケース変更に対応
    if (
      (error.response?.data || '').toLowerCase() === 'invalid session token'
    ) {
      await store.dispatch('logout')
      redirect('/login')
      return
    }
    if (error.response && error.response.status === 413) {
      error.response.data = errorMessages.uploadVolumeExceeded
    }
    throw error
  })
}
