export const state = () => ({
  unreadCount: 0,
  beforeUpdateUnreadCountUnixTime: undefined,
  chatList: null
})

export const getters = {
  unreadChatMessageCount: state => state.unreadCount
}

export const mutations = {
  setUnreadCount(state, { unreadCount, updateTime }) {
    state.unreadCount = unreadCount
    state.beforeUpdateUnreadCountUnixTime = updateTime
  }
}

export const actions = {
  async updateUnreadCount({ commit }) {
    const { unreadCount } = await this.$chatRepository.getUnreadCount()
    commit('setUnreadCount', {
      unreadCount,
      updateTime: Date.now()
    })
  },
  async updateUnreadCountIfNeeded({ commit }) {
    // 各種リンクをクリックした時に通知を更新する仕様のためパフォーマンスを気にせず毎回取得する。もし問題があればここを改善する
    // const intervalTimeMs = 5 * 60 * 1000 // 5min
    // const elapsedTimeMs = Date.now() - state.beforeUpdateUnreadCountUnixTime
    // if (
    //   state.beforeUpdateUnreadCountUnixTime &&
    //   intervalTimeMs > elapsedTimeMs
    // ) {
    //   return
    // }

    const { unreadCount } = await this.$chatRepository.getUnreadCount()
    commit('setUnreadCount', {
      unreadCount,
      updateTime: Date.now()
    })
  }
}
