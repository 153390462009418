import constant from '~/helper/constant'

export const getTopPath = (role: string): string => {
  if (role.toLowerCase() === constant.roles.doctor) {
    return '/'
  }

  if (role.toLowerCase() === constant.roles.coMedical) {
    return '/top/medical'
  }

  if (role.toLowerCase() === constant.roles.ms) {
    return '/top/ms'
  }

  return '/'
}
