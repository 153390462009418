import HttpRepository from './httpRepository'

export default class HospitalNoticeRepository extends HttpRepository {
  create(params: {
    title: string
    detail: string
    toHospitalObjectIds: string[]
    fileUrl: string
    filename: string
  }): Promise<void> {
    return this.post('/api/hospitalNotices', params)
  }

  update(params: {
    title: string
    detail: string
    fileUrl: string
    filename: string
    objectId: string
  }): Promise<void> {
    return this.patch(`/api/hospitalNotices/${params.objectId}`, params)
  }

  fetchDetail(obejctId: string): Promise<any> {
    return this.get(`/api/hospitalNotices/${obejctId}`)
  }

  fetchList(limit: number, offset: number): Promise<any> {
    const params: Partial<{ limit: number; offset: number }> = {}
    if (limit) {
      params.limit = limit
    }

    if (offset) {
      params.offset = offset
    }

    return this.get(`/api/hospitalNotices`, { params })
  }

  async fetchOwnCreatedList(): Promise<any> {
    const data = await this.get('/api/hospitalNotices/own')
    return data || []
  }

  deleteHospitalNotice(objectId: string): Promise<any> {
    return this.delete(`/api/hospitalNotices/${objectId}`)
  }

  getHospitalNoticeUnreadCount(): Promise<any> {
    return this.get('/api/hospitalNoticeUnreadCount')
  }
}
