import HttpRepository from './httpRepository'
import { DocsiruHospital } from '~/types/api'

export default class HospitalRepository extends HttpRepository {
  update(params: Partial<DocsiruHospital>): Promise<void> {
    return this.patch('/api/hospitals', params)
  }

  getForRegisterComedical(objectId: string): Promise<any> {
    return this.get('/api/hospitals/forRegisterComedical/' + objectId)
  }
}
