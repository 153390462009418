<template>
  <VIcon
    v-if="type === 'circle_dots'"
    class="circle-dots"
    :color="colorType"
    :size="size"
    :style="{ borderColor: colorType }"
    >{{ iconType }}</VIcon
  >
  <VIcon
    v-else
    :color="colorType"
    :size="size"
    :class="{ '-reverse-horizontal': reverseHorizontal }"
    >{{ iconType }}</VIcon
  >
</template>

<script>
import '~/assets/icon/icomoon/style_ver1.css'
import '~/assets/icon/icomoon/style_ver2.css'
import '~/assets/icon/icomoon/style_ver3.css'
import '~/assets/icon/icomoon/style_ver4.css'
import '~/assets/icon/icomoon/style_ver5.css'
import '~/assets/icon/icomoon/style_ver6.css'
import '~/assets/icon/icomoon/style_ver7.css'
import '~/assets/icon/icomoon/style_ver8.css'
import '~/assets/icon/icomoon/style_ver9.css'
import '~/assets/icon/icomoon/style_ver10.css'
import '~/assets/icon/icomoon/style_ver11.css'
import '~/assets/icon/icomoon/style_ver12.css'
import '~/assets/icon/icomoon/style_ver13.css'
import '~/assets/icon/icomoon/style_ver14.css'
import '~/assets/icon/icomoon/style_ver15.css'
import '~/assets/icon/icomoon/style_ver16.css'

import { customColorProfileKeys } from '~/helper/colors'
import { isValidColorCode } from '~/helper'

export const iconTypes = {
  house: 'icon-house',
  'circle-right': 'icon-left_circle',
  circle_left: 'mdi-chevron-left-circle',
  circle_dots: 'mdi-dots-horizontal',
  alert: 'icon-alert',
  arrow_bottom: 'icon-arrow_bottom',
  arrow_check: 'icon-arrow_check',
  arrow_left: 'icon-arrow_left',
  arrow_right: 'icon-arrow_right',
  arrow_top: 'icon-arrow_top',
  backup: 'icon-backup',
  community: 'icon-community',
  bed: 'icon-bed',
  camera: 'icon-camera',
  checked: 'icon-checked',
  close_big: 'icon-close_big',
  close: 'icon-close',
  condition: 'icon-condition',
  doctor_active: 'icon-doctor_active',
  doctor: 'icon-doctor',
  exchange: 'icon-exchange',
  fax: 'icon-fax',
  home_active: 'icon-home_active',
  home: 'icon-home',
  hospital_active: 'icon-hospital_active',
  hospital: 'icon-hospital',
  invite: 'icon-invite',
  map: 'icon-map',
  medical_staff: 'icon-medical_staff',
  message_active: 'icon-message_active',
  message: 'icon-message',
  more_menu: 'icon-more_menu',
  open: 'icon-open',
  oval_line: 'icon-oval_line',
  oval: 'icon-oval',
  patient_date: 'icon-patient_date',
  pen: 'icon-pen',
  phone: 'icon-phone',
  pulldown: 'icon-pulldown',
  report: 'icon-report',
  result: 'icon-result',
  search: 'icon-search',
  sort: 'icon-sort',
  traiangle: 'icon-traiangle',
  web: 'icon-web',
  sms: 'icon-sms',
  line: 'icon-LINE',
  email: 'icon-email',
  clip: 'icon-clip',
  list: 'icon-list',
  list_add: 'icon-list_add',
  new_message: 'icon-new_message',
  plus: 'mdi-plus',
  outpatient: 'icon-outpatient',
  hand: 'icon-hand',
  quote_open: 'mdi-format-quote-open',
  quote_close: 'mdi-format-quote-close',
  add_file: 'icon-add_file',
  exclamation_mark: 'icon-exclamation_mark',
  timeline_comment: 'icon-timeline_comment',
  timeline_notice: 'icon-timeline_notice',
  timeline_reaction: 'icon-timeline_reaction',
  timeline_invitation: 'icon-invite-member',
  lock: 'mdi-lock',
  medical_notice: 'icon-medical-notice',
  dots_horizontal_circle: 'mdi-dots-horizontal-circle',
  dots_vertical: 'mdi-dots-vertical',
  dots_horizontal: 'mdi-dots-horizontal',
  check: 'mdi-check',
  exit: 'icon-exit',
  delete: 'icon-delete',
  join: 'icon-join',
  eye_on: 'mdi-eye',
  eye_off: 'mdi-eye-off',
  clear: 'mdi-close-circle',
  group_admin: 'icon-group-admin',
  invite_member: 'icon-invite-member',
  group_member: 'icon-group-member',
  triangle_top: 'mdi-triangle',
  triangle_btm: 'icon-triangle-btm',
  note: 'icon-note',
  muted_filled: 'icon-muted_filled',
  check_circle_thin: 'icon-check_circle_thin',
  triangle_filled_right: 'icon-triangle_filled_right',
  account_circle: 'mdi-account-circle-outline',
  list_bulled: 'mdi-format-list-bulleted',
  alert_circle: 'mdi-alert-circle-outline',
  chevron_right: ' mdi-chevron-right',
  home_variant: 'mdi-home-variant',
  account_group: 'mdi-account-group',
  bell: 'icon-bell',
  question_circle: 'icon-question_circle',
  edit: 'icon-edit',
  plus_rounded: 'icon-plus',
  calendar: 'icon-calendar',
  image: 'icon-image',
  chevron_rigth_rounded: 'icon-chevron-rigth-rounded',
  chevron_left_rounded: 'icon-chevron-left-rounded',
  business_card: 'icon-business-card',
  search_filled: 'icon-search_filled',
  message_filled: 'icon-message_filled',
  baseline_image: 'icon-Typebaseline-image',
  accept: 'icon-Typeaccept',
  add_1: 'icon-TypeAdd-1',
  add: 'icon-Typeadd',
  baseline_qr_code: 'icon-Typebaseline-qr-code',
  bell_nortification: 'icon-Typebell_Nortification',
  type_bell: 'icon-Typebell',
  calendartoday: 'icon-TypeCalendartoday',
  card: 'icon-TypeCard',
  type_close_big: 'icon-Typeclose-big',
  type_community: 'icon-TypeCommunity',
  deny: 'icon-Typedeny',
  folder_check: 'icon-Typefolder_check',
  fountain_pen: 'icon-Typefountain-pen',
  type_home: 'icon-Typehome',
  type_image: 'icon-Typeimage',
  keyboard_arrow_down: 'icon-TypeKeyboard_arrow_down',
  keyboard_arrow_left: 'icon-TypeKeyboard_arrow_left',
  keyboard_arrow_right: 'icon-TypeKeyboard_arrow_right',
  keyboard_arrow_top: 'icon-TypeKeyboard_arrow_top',
  label: 'icon-Typelabel',
  link: 'icon-Typelink',
  type_list: 'icon-Typelist',
  mail: 'icon-Typemail',
  type_message: 'icon-Typemessage',
  message2: 'icon-TypeMessage2',
  more_horiz: 'icon-Typemore_horiz',
  outline_info: 'icon-Typeoutline-info',
  people: 'icon-TypePeople',
  person: 'icon-TypePerson',
  person2: 'icon-TypePerson2',
  profile: 'icon-Typeprofile',
  round_edit_note: 'icon-Typeround-edit-note',
  round_note_add: 'icon-Typeround-note-add',
  round_place: 'icon-Typeround-place',
  round_sort: 'icon-Typeround-sort',
  type_search: 'icon-Typesearch',
  search2: 'icon-Typesearch2',
  type_sort: 'icon-Typesort',
  tag: 'icon-Typetag',
  add_notes: 'icon-add_notes',
  cached: 'icon-cached',
  cloud_upload: 'icon-cloud_upload',
  drag_indicator: 'icon-DragIndicator',
  help_outline: 'icon-Help_outline',
  person_search: 'icon-Person-search',
  user_doctor: 'icon-user-Doctor',
  user_ms: 'icon-user-MS',
  visibillity_off: 'icon-visibillity_off',
  visibilly: 'icon-visibilly'
}

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: undefined
    },
    reverseHorizontal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconType() {
      const iconType = iconTypes[this.type]
      if (!iconType) {
        throw new Error(`invalid icon type. ${this.type}`)
      }
      return iconType
    },
    // ToDo propsのvalidatorを使う、カラーコード定義を共通化する
    colorType() {
      const colors = [
        ...customColorProfileKeys,
        'light-gray',
        'dark-gray',
        'yellow',
        'black',
        'red',
        'newgreen',
        'white'
      ]
      if (!colors.includes(this.color) && !isValidColorCode(this.color)) {
        throw new Error(`想定しないcolorです。${this.color}`)
      }
      if (this.color === 'light-gray') {
        return '#d5d5d5'
      }
      if (this.color === 'dark-gray') {
        return '#2c2c2c'
      }
      if (this.color === 'yellow') {
        return '#FFE100'
      }
      if (this.color === 'black') {
        return '#4B4B4B'
      }
      if (this.color === 'red') {
        return '#e20000'
      }
      if (this.color === 'newgreen') {
        return '#2ecc71'
      }
      return this.color
    }
  }
}
</script>

<style lang="scss" scoped>
.circle-dots {
  border: 1px solid;
  border-radius: 50%;
  padding: 4px;
}

.-reverse-horizontal {
  transform: scaleX(-1);
}
</style>
