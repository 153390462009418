import {
  FORM_LIMIT_GROUP_TITLE,
  FORM_LIMIT_GROUP_DESCRIPTION,
  FORM_LIMIT_GROUP_NOTICE_TITLE,
  FORM_LIMIT_GROUP_NOTICE_DESCRIPTION,
  FORM_LIMIT_HOSPITAL_NOTICE_TITLE,
  FORM_LIMIT_HOSPITAL_NOTICE_DESCRIPTION,
  FORM_LIMIT_GROUP_INVITE_MESSAGE
} from '~/helper/enum'

export const errorMessages = {
  common: 'エラーが発生しました。恐れ入りますがもう一度お試しください。',
  uploadVolumeExceeded:
    'ファイルの容量がアップロード上限の10MBを超えています。',
  internalServerError:
    'システムエラーが発生しました。時間をおいて試しても解決しない場合、恐れ入りますがトップページからお問い合わせください。',
  emailAlreadyExist: 'このメールアドレスはすでに存在しています',
  invalidEmail: '不正なメールアドレスです',
  invalidPassword: 'パスワードは6文字以上の英数字で設定してください',
  invalidCommunityNameLength: `コミュニティ名は${FORM_LIMIT_GROUP_TITLE}文字以内で入力してください`,
  invalidCommunityDescriptionLength: `コミュニティの説明文は${FORM_LIMIT_GROUP_DESCRIPTION}文字以内で入力してください`,
  invalidGroupNoticeTitleLength: `お知らせの件名は${FORM_LIMIT_GROUP_NOTICE_TITLE}文字以内で入力してください`,
  invalidGroupNoticeDetailLength: `投稿内容の本文は${FORM_LIMIT_GROUP_NOTICE_DESCRIPTION}文字以内で入力してください`,
  invalidHospitalNoticeTitleLength: `お知らせの件名は${FORM_LIMIT_HOSPITAL_NOTICE_TITLE}文字以内で入力してください`,
  invalidHospitalNoticeDetailLength: `投稿内容の本文は${FORM_LIMIT_HOSPITAL_NOTICE_DESCRIPTION}文字以内で入力してください`,
  invalidGroupInviteMessageLength: `招待コメントは${FORM_LIMIT_GROUP_INVITE_MESSAGE}文字以内で入力してください`,
  requireTitle: '件名を入力してください。',
  requireDetail: '内容を入力してください。',
  requireGroup: '投稿するコミュニティを選択してください。',
  requireGroupName: 'コミュニティ名を入力してください。',
  requireGroupDescription: 'コミュニティ紹介を入力してください。',
  requireGroupInviteMessage: '招待コメントを入力してください',
  requireManager: '支店長以上の管理職を追加してください',
  requireComment: 'コメントを入力してください',
  failedToUploadImage: '画像のアップロードに失敗しました',
  failedToExitFromGroup:
    'コミュニティから退出が出来ませんでした。再度お試しください',
  failedToRemoveGroupMember:
    'メンバーの削除が出来ませんでした。再度お試しください',
  failedToChangeMemberRole:
    'メンバーの権限の変更が出来ませんでした。再度お試しください',
  failedToInviteMember: 'メンバー招待が出来ませんでした。再度お試しください',
  youHaveAlreadyAdminRole: 'あなたは既に管理者として登録されています',
  networkDisconnected:
    'ネットワークに接続されていません。時間をおいて再度お試しください。',
  groupNoticeAlreadyDeleted: 'この投稿はすでに削除されています。'
}

export const successMessages = {
  createList: 'リストを作成しました。',
  save: '保存しました。',
  delete: '削除しました。',
  shouldCreateList: '追加するリストを作成してください。',
  removeList: 'リストから削除しました。',
  updateList: 'リストを更新しました。',
  addList: 'リストに追加しました。',
  deleteList: 'リストを削除しました。',
  createNotice: 'お知らせを作成しました。',
  updateNotice: 'お知らせの編集が完了しました',
  deleteHospitalNotice: 'お知らせの削除が完了しました',
  updateGroupName: 'グループ名を更新しました。',
  leftGroup: 'グループ退出しました。',
  deleteMember: 'メンバーを削除しました。',
  createGroup: 'コミュニティの作成が完了しました',
  exitFromGroup: 'コミュニティの退出が完了しました',
  deleteGroup: 'コミュニティの削除が完了しました',
  updateGroup: 'コミュニティの編集が完了しました',
  createGroupNotice: '投稿が完了しました',
  deleteGroupNotice: '投稿を削除しました',
  createGroupNoticeComment: 'コメントを投稿しました',
  deleteGroupNoticeComment: 'コメントを削除しました',
  uploadImage: '画像のアップロードに成功しました',
  removeGroupMember: 'メンバーの削除を完了しました',
  changeMemberRole: 'メンバーの権限を変更しました',
  becomeAdmin: '管理者権限の付与が完了しました',
  inviteMember: 'メンバーの招待が完了しました',
  cancelInviteMember: '招待を取り消しました',
  approveGroupInvitation: 'コミュニティへの参加が完了しました',
  disapproveGroupInvitation: '招待を辞退しました',
  deletePaper: '論文の削除が完了しました',
  updateUserEmailSettings: 'メール受信設定が完了しました',
  updateDoctorProfile: 'プロフィールの保存が完了しました',
  updateMsProfile: 'プロフィールの保存が完了しました',
  businessCard: {
    sendRequest: 'リクエストが完了しました',
    exchangeCompleted: '名刺の交換が完了しました',
    refuseRequest: '申請を辞退しました'
  }
}

export const PROGRESS_MESSAGE = {
  UPLOADING_IMAGE: '画像をアップロード中です'
}
