import HttpRepository from './httpRepository'
import { groupNoticeRepositoryFile } from '~/types/api'

export default class GroupNoticeRepository extends HttpRepository {
  /**
   * 参加している(もしくは特定の)グループの投稿一覧を取得する
   *
   * @param {{ offset: number | undefined, limit: number | undefined }} params
   * @returns {<DocsiruGroupNotice>[]}
   */
  findGroupNotices(
    params: {
      limit: number | undefined
      offset: number | undefined
      groupId: string | undefined
    } = {
      limit: 2000,
      offset: 0,
      groupId: undefined
    }
  ): Promise<any> {
    return this.get('/api/group_notices', { params })
  }

  getGroupNotice(groupNoticeId: string): Promise<any> {
    return this.get(`/api/group_notices/${groupNoticeId}`)
  }

  findGroupNoticeTimeline(
    params: {
      limit: number | undefined
      offset: number | undefined
      type: string | undefined
    } = {
      limit: 2000,
      offset: 0,
      type: undefined
    }
  ): Promise<any> {
    return this.get('/api/group_notice_timeline', { params })
  }

  async createGroupNotice(params: {
    title: string
    detail: string
    groupId: string
    fileUrl: string
  }): Promise<void> {
    await this.post('/api/group_notices', params)
  }

  async updateGroupNoticeReaction(
    docsiruGroupNoticeId: string,
    reactionType: string
  ): Promise<void> {
    await this.patch('/api/group_notices/update_group_notice_reaction', {
      docsiruGroupNoticeId,
      reactionType
    })
  }

  async updateGroupNoticeCommentReaction(
    docsiruGroupNoticeCommentId: string,
    reactionType: string
  ): Promise<void> {
    await this.patch(
      '/api/group_notices/update_group_notice_comment_reaction',
      {
        docsiruGroupNoticeCommentId,
        reactionType
      }
    )
  }

  findGroupNoticeReaction(id: string): Promise<any> {
    return this.get(`/api/group_notices/${id}/find_group_notice_reaction`)
  }

  findGroupNoticeCommentReaction(id: string): Promise<any> {
    return this.get(
      `/api/group_notices/${id}/find_group_notice_comment_reaction`
    )
  }

  async updateLastRead(groupNoticeId: string): Promise<void> {
    await this.patch(`/api/group_notices/${groupNoticeId}/updateLastRead`)
  }

  async deleteNotice(groupNoticeId: string): Promise<void> {
    await this.delete(`/api/group_notices/${groupNoticeId}`)
  }

  async createComment(
    groupNoticeId: string,
    comment: string,
    media: groupNoticeRepositoryFile
  ): Promise<void> {
    await this.post(`/api/group_notices/${groupNoticeId}/comment`, {
      noticeId: groupNoticeId,
      comment,
      media
    })
  }

  async deleteComment(groupNoticeId: string, commentId: string): Promise<void> {
    await this.delete(
      `/api/group_notices/${groupNoticeId}/comment/${commentId}`
    )
  }
}
