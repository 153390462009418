import { Plugin } from '@nuxt/types'
import constant from '~/helper/constant'
export type Logger = {
  noticeError: (error: any) => void
}

const plugin: Plugin = (context, inject) => {
  const logger: Logger = {
    noticeError: error => {
      // TODO: sentryを入れる
      if (constant.stage === 'local') {
        console.error(error)
      }
    }
  }

  context.$logger = logger
  inject('logger', logger)
}

export default plugin
