export const setStorage = (
  key: string,
  value: string | number | string[],
  expire: number | undefined
): void => {
  let data
  if (expire !== undefined) {
    data = {
      expire,
      value
    }
  } else {
    data = value
  }
  localStorage.setItem(key, JSON.stringify(data))
}

export const getStorage = (
  key: string
): string | number | string[] | undefined => {
  let s:
    | string
    | null
    | {
        expire: number
        value: string | number
      } = localStorage.getItem(key)
  if (s === undefined || s === null) {
    return undefined
  }
  s = JSON.parse(s)

  if (!s) return ''

  if (typeof s === 'string' || typeof s === 'number' || Array.isArray(s)) {
    return s
  }

  if (new Date(s.expire) > new Date()) {
    return s.value
  } else {
    localStorage.removeItem(key)
    return undefined
  }
}
