import get from 'lodash.get'
// import debounce from 'lodash.debounce'
import constant from '~/helper/constant'
import { COMPANY_CODE_GW, COMPANY_CODE_DC } from '~/helper/enum'
import { encodeUserIdWithRole } from '~/helper'

export const state = () => ({
  currentUser: null, // ここに記録する情報はクライアントで見える
  doctorCategory: null,
  profile: null,
  toast: {
    type: null,
    message: null,
    timeout: null
  },
  companyList: null,
  companyListWithInternalItem: null,
  prefectureCompanyList: null,
  refreshIntervalId: null,
  latestTermsVersion: null,
  myPageUnreadCount: null
})

export const getters = {
  isAuth: state => state.currentUser !== null,
  currentUserId: (state, getters) => {
    const profile = state.profile
    if (profile === undefined || profile === null) return undefined
    if (getters.isRoleDoctor) {
      return profile.hpcrUser?.objectId
    }
    if (getters.isRoleMs) {
      return profile.user?.objectId
    }
    return undefined
  },
  currentId: state => {
    return state.profile?.objectId ?? undefined
  },
  currentIdWithUserRole: (state, getters) => {
    const currentId = state.profile?.objectId
    const currentUserRole = getters.currentUserRole
    return currentId
      ? encodeUserIdWithRole(currentId, currentUserRole)
      : undefined
  },
  currentDoctorId: (state, getters) => {
    if (!getters.isRoleDoctor) return undefined
    return state.profile?.doctorId ?? undefined
  },
  currentMsId: state => {
    return state.profile?.objectId ?? undefined
  },
  currentUserBelongedCompanyCode: getters => {
    return get(getters.profile, 'companyCode', undefined)
  },
  isCurrentUserBelongedAdminCompany: getters => {
    return get(getters.profile, 'companyCode', undefined) === COMPANY_CODE_DC
  },
  currentUserBelongedCompanyName: getters => {
    return get(getters.profile, 'companyName', undefined)
  },
  currentUserBelongedOrganization: getters => {
    return get(getters.profile, 'organization', undefined)
  },
  companyList: state => {
    return get(state, 'companyList', [])
  },
  prefectureCompanyList: state => {
    return get(state, 'prefectureCompanyList', [])
  },
  inquiryMailAddressGW: state => {
    const companyList = get(state, 'companyListWithInternalItem', [])
    const GWItem =
      companyList?.length > 0
        ? companyList.find(item => item.companyCode === COMPANY_CODE_GW)
        : undefined
    return GWItem?.inquiryMailAddress ?? ''
  },
  replyingCompanyList: getters => {
    const companyList = getters.companyList
    return companyList?.length > 0
      ? [
          {
            companyCode: COMPANY_CODE_GW,
            text: '運営会社（株式会社ゲッカワークス）の回答を希望する',
            inquiryMailAddress: getters.inquiryMailAddressGW
          },
          ...companyList.map(item => {
            return {
              ...item,
              text: `${item.companyName}担当者の回答を希望する`,
              inquiryMailAddress: item.inquiryMailAddress
            }
          })
        ]
      : []
  },
  currentUserRole: state => {
    return state.currentUser?.role ?? undefined
  },
  currentUser: state => {
    return get(state, 'currentUser', undefined)
  },
  profile: state => {
    return get(state, 'profile', undefined)
  },
  isRoleDoctor: state => {
    if (state.currentUser === null || state.currentUser.role === null) {
      return false
    }
    return state.currentUser.role.toLowerCase() === constant.roles.doctor
  },
  isRoleCoMedical: state => {
    if (state.currentUser === null || state.currentUser.role === null) {
      return false
    }
    return state.currentUser.role.toLowerCase() === constant.roles.coMedical
  },
  isRoleMs: state => {
    if (state.currentUser === null || state.currentUser.role === null) {
      return false
    }
    return state.currentUser.role.toLowerCase() === constant.roles.ms
  },
  unreadNotificationCount: state =>
    (state.profile || {}).unreadNotificationCount || 0,
  refreshIntervalId: state => {
    return get(state, 'refreshIntervalId', undefined)
  },
  hasAgreedTermsOfServiceVersion: state =>
    state.currentUser?.agreedTermsOfServiceVersion === state.latestTermsVersion,
  latestTermsVersion: state => state.latestTermsVersion,
  myPageUnreadCount: state => state.myPageUnreadCount
}

export const mutations = {
  SET_currentUser(state, user) {
    state.currentUser = user
  },
  SET_doctorCategory(state, doctorCategory) {
    state.doctorCategory = doctorCategory
  },
  setCompanyList(state, payload) {
    state.companyList = payload
  },
  setCompanyListWithInternalItem(state, payload) {
    state.companyListWithInternalItem = payload
  },
  setPrefectureCompanyList(state, payload) {
    state.prefectureCompanyList = payload
  },
  setCurrentuserEmail(state, email) {
    state.currentUser.email = email
  },
  setProfile(state, profile) {
    if (Object.keys(profile).length > 0) {
      state.profile = profile
    }
  },
  SET_toast(state, { type, message }) {
    state.toast = {
      ...state.toast,
      type,
      message
    }
  },
  SET_toast_timeout(state, { timeout }) {
    state.toast = {
      ...state.toast,
      timeout
    }
  },
  clearRefreshInterval(state) {
    const intervalId = state.refreshIntervalId
    if (intervalId) {
      clearInterval(intervalId)
      state.refreshIntervalId = null
    }
  },
  setRefreshInterval(state, payload) {
    if (state.refreshIntervalId) {
      return
    }
    state.refreshIntervalId = payload
  },
  setLatestTermsVersion(state, payload) {
    state.latestTermsVersion = payload
  },
  setAgreedTermsOfServiceVersion(state, payload) {
    state.currentUser.agreedTermsOfServiceVersion = payload
  },
  setMyPageUnreadCount(state, payload) {
    state.myPageUnreadCount = payload
  }
}

function setUserInfo(currentUser) {
  return {
    username: currentUser.username,
    lastnameJa: currentUser.lastnameJa,
    firstnameJa: currentUser.firstnameJa,
    lastname: currentUser.lastname,
    firstname: currentUser.firstname,
    objectId: currentUser.objectId,
    email: currentUser.email,
    role: currentUser.role,
    category2: currentUser.category2,
    certificationYear: currentUser.certificationYear,
    gender: currentUser.gender,
    route: currentUser.route,
    agreedTermsOfServiceVersion: currentUser.agreedTermsOfServiceVersion,
    agreedPrivacyPolicyVersion: currentUser.agreedPrivacyPolicyVersion
  }
}

export const actions = {
  nuxtServerInit(store, { req }) {
    if (req.session && req.session.currentUser) {
      store.commit('SET_currentUser', setUserInfo(req.session.currentUser))
      store.commit('SET_doctorCategory', req.session.doctorCategory || [])
    } else {
      store.commit('SET_currentUser', null)
    }
  },

  async logout({ commit }) {
    await this.$axios.post('/api/logout').catch(err => {
      throw new Error(err)
    })
    commit('SET_currentUser', null)
  },

  async getProfile({ commit }) {
    const res = await this.$axios.get('/api/profile').catch(err => {
      throw new Error(err)
    })
    commit('setProfile', res.data)
  },

  async getCompanyList({ commit, getters }) {
    const res = await this.$axios.get('/api/companyList').catch(err => {
      throw new Error(err)
    })
    commit('setCompanyListWithInternalItem', res.data)
    const companies =
      getters.isCurrentUserBelongedAdminCompany && getters.isRoleMs
        ? res.data.filter(item => item.companyCode !== COMPANY_CODE_GW)
        : res.data.filter(item => !item?.isInternal)
    commit('setCompanyList', companies)
  },

  async getPrefectureCompanyList({ commit }) {
    const res = await this.$axios
      .get('/api/prefectureCompanyList')
      .catch(err => {
        throw new Error(err)
      })
    commit('setPrefectureCompanyList', res.data)
  },

  async getLatestTermsOfServiceVersion({ commit }) {
    const {
      version
    } = await this.$userRepository
      .getLatestTermsOfServiceVersion()
      .catch(err => {
        throw new Error(err)
      })
    commit('setLatestTermsVersion', version)
  },

  async updateAgreedTermsOfServiceVersion({ commit, getters }) {
    const res = await this.$userRepository
      .updateAgreedTermsOfServiceVersion(getters.latestTermsVersion)
      .catch(err => {
        throw new Error(err)
      })
    commit('setAgreedTermsOfServiceVersion', res)
  },

  showErrorToast({ commit, state }, { message }) {
    clearTimeout(state.toast.timeout)
    commit('SET_toast', { type: 'error', message })
    const timeout = setTimeout(() => {
      commit('SET_toast', { type: null, message: null })
      commit('SET_toast_timeout', { timeout: null })
    }, 5000)
    commit('SET_toast_timeout', { timeout })
  },

  hideErrorToast({ commit, state }) {
    clearTimeout(state.toast.timeout)
    commit('SET_toast', { type: null, message: null })
    commit('SET_toast_timeout', { timeout: null })
  },

  showSuccessToast({ commit, state }, { message }) {
    clearTimeout(state.toast.timeout)
    commit('SET_toast', { type: 'success', message })
    const timeout = setTimeout(() => {
      commit('SET_toast', { type: null, message: null })
      commit('SET_toast_timeout', { timeout: null })
    }, 2000)
    commit('SET_toast_timeout', { timeout })
  },
  setRefreshInterval({ commit }, intervalId) {
    commit('clearRefreshInterval')
    commit('setRefreshInterval', intervalId)
  },
  clearRefreshInterval({ commit }) {
    commit('clearRefreshInterval')
  },
  async fetchMyPageUnreadCount({ commit }) {
    const res = await this.$userRepository.getMypageUnreadCount().catch(err => {
      throw new Error(err)
    })
    commit('setMyPageUnreadCount', res.count)
    commit('notification/setUnreadCount', res.notification, { root: true })
    commit('businessCard/setUnreadCount', res.businessCardUnread, {
      root: true
    })
    commit('businessCard/setRequestUnreadCount', res.businessCardRequest, {
      root: true
    })
  }
  // fetchMyPageUnreadCount: debounce(async function ({ commit }) {
  //   const res = await this.$userRepository.getMypageUnreadCount().catch(err => {
  //     throw new Error(err)
  //   })
  //   commit('setMyPageUnreadCount', res.count)
  //   commit('notification/setUnreadCount', res.notification, { root: true })
  //   commit('businessCard/setUnreadCount', res.businessCardUnread, {
  //     root: true
  //   })
  //   commit('businessCard/setRequestUnreadCount', res.businessCardRequest, {
  //     root: true
  //   })
  // }, 0)
}
