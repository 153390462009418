import { Plugin } from '@nuxt/types'

const updateQueryString: Plugin = ({ app }, inject) => {
  inject(
    'updateQueryString',
    (key: string, value: string, mode: 'push' | 'replace' = 'push') => {
      const { context, router } = app
      const route = context.route
      const query = { ...route.query, [key]: value }
      const newRoute = { path: route.path, query }
      if (!router) return
      if (mode === 'replace') {
        router.replace(newRoute)
        return
      }
      router.push(newRoute)
    }
  )
}

export default updateQueryString
