import HttpRepository from './httpRepository'
import { EnrollmentPeriod, MeshData } from '~/types/api'

export default class AuthRepository extends HttpRepository {
  login({
    username,
    password
  }: {
    username: string
    password: string
  }): Promise<void> {
    return this.post('/api/login', {
      username,
      password
    })
  }

  isValidMailAuthCode({
    email,
    code
  }: {
    email: string
    code: string
  }): Promise<void> {
    return this.post('/api/isValidMailAuthCode', {
      email,
      code
    })
  }

  isValidEmail({ email }: { email: string }): Promise<void> {
    return this.post('/api/isValidEmail', { email })
  }

  sendMailAuthCode({ email }: { email: string }): Promise<void> {
    return this.post('/api/sendMailAuthCode', { email })
  }

  isValidDoctor({
    firstnameJa,
    lastnameJa,
    firstnameKana,
    lastnameKana,
    gender,
    certificationYear,
    certificationMonth,
    certificationDate,
    registeredNumber,
    email,
    category2,
    subCategoryId,
    birthday
  }: {
    firstnameJa: string
    lastnameJa: string
    firstnameKana: string
    lastnameKana: string
    gender: number
    certificationYear: string
    certificationMonth: string
    certificationDate: string
    registeredNumber: string
    email: string
    category2: number
    subCategoryId: number
    birthday: string
  }): Promise<any> {
    return this.post('/api/isValidDoctor', {
      firstnameJa,
      lastnameJa,
      firstnameKana,
      lastnameKana,
      gender: gender.toString(),
      certificationYear,
      certificationMonth,
      certificationDate,
      registeredNumber: registeredNumber.toString(),
      email,
      category2: category2.toString(),
      subCategoryId: subCategoryId.toString(),
      birthday
    })
  }

  registerUser({
    email,
    password,
    firstnameJa,
    lastnameJa,
    firstnameKana,
    lastnameKana,
    certificationYear,
    certificationMonth,
    certificationDate,
    registeredNumber,
    gender,
    category2,
    subCategoryId,
    isTemporary,
    birthday,
    hospitalName,
    hospitalPref,
    hospitalCity,
    code
  }: {
    email: string
    password: string
    firstnameJa: string
    lastnameJa: string
    firstnameKana: string
    lastnameKana: string
    gender: number
    certificationYear: number
    certificationMonth: string
    certificationDate: string
    registeredNumber: number
    category2: number
    subCategoryId: number
    isTemporary: boolean
    birthday: string
    hospitalName: string
    hospitalPref: string
    hospitalCity: string
    code: string
  }): Promise<any> {
    return this.post('/api/registerUser', {
      email,
      password,
      firstnameJa,
      lastnameJa,
      firstnameKana,
      lastnameKana,
      certificationYear: certificationYear.toString(),
      certificationMonth,
      certificationDate,
      registeredNumber: registeredNumber.toString(),
      gender: gender.toString(),
      category2,
      subCategoryId,
      isTemporary,
      birthday,
      hospitalName,
      hospitalCity,
      hospitalPref,
      code,
      role: 'doctor'
    })
  }

  createDoctor({
    user,
    sessionToken,
    doctorObjectId,
    password,
    category2,
    subCategoryId,
    doctorExpertCategory,
    searchPaperMeshList,
    surgeryMeshList,
    unacceptableMeshList,
    introduction,
    university,
    graduationYear,
    alfresaCode,
    originalEnrollmentPeriodList,
    enrollmentPeriodList,
    hideUniversity,
    birthday,
    hospitalId,
    hospitalName,
    hospitalPrefecture,
    hideCertificationYear,
    hideGender,
    isTemporary
  }: {
    user: any
    sessionToken: string
    doctorObjectId: string | undefined
    password: string
    category2: number
    subCategoryId: number
    doctorExpertCategory: number[]
    searchPaperMeshList: MeshData[]
    surgeryMeshList: MeshData[]
    unacceptableMeshList: MeshData[]
    introduction: string
    university: string
    graduationYear: string
    alfresaCode: string
    originalEnrollmentPeriodList?: EnrollmentPeriod[]
    enrollmentPeriodList: EnrollmentPeriod[]
    hideUniversity: string
    birthday: string
    hospitalId: string
    hospitalName: string
    hospitalPrefecture: string
    hideCertificationYear: string
    hideGender: string
    isTemporary: boolean
  }): Promise<any> {
    return this.post(`/api/doctor`, {
      user,
      sessionToken,
      doctorObjectId,
      password,
      category2,
      subCategoryId,
      doctorExpertCategory,
      searchPaperMeshList,
      surgeryMeshList,
      unacceptableMeshList,
      introduction,
      university,
      graduationYear,
      alfresaCode,
      originalEnrollmentPeriodList,
      enrollmentPeriodList,
      hideUniversity,
      birthday,
      hospitalId,
      hospitalName,
      hospitalPrefecture,
      hideCertificationYear,
      hideGender,
      isTemporary
    })
  }
}
