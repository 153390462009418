import HttpRepository from './httpRepository'
import { BusinessCardUnreadCount } from '~/types/api'

export default class BusinessCardRepository extends HttpRepository {
  getBusinessCardByUserId(objectId: string): Promise<any> {
    return this.post('/api/businessCard/getBusinessCardByUserId', {
      objectId
    })
  }

  findExchangeLog(params: {
    requester?: string
    recipient?: string
  }): Promise<any> {
    return this.post('/api/businessCard/findExchangeLog', params)
  }

  getExchangeStatus(params: {
    requester: string
    recipient: string
  }): Promise<any> {
    return this.post('/api/businessCard/getExchangeStatus', params)
  }

  requestExchange(params: {
    recipient: string
    message?: string
  }): Promise<void> {
    return this.post('/api/businessCard/requestExchange', params)
  }

  // 名刺機能リリース1では使用しない
  cancelExchange(objectId: string): Promise<void> {
    return this.post('/api/businessCard/cancelExchange', {
      objectId
    })
  }

  rejectExchange(objectId: string): Promise<void> {
    return this.post('/api/businessCard/rejectExchange', {
      objectId
    })
  }

  acceptExchange(objectId: string): Promise<void> {
    return this.post('/api/businessCard/acceptExchange', {
      objectId
    })
  }

  getBusinessCardRequestCount(): Promise<any> {
    return this.get('/api/businessCard/getBusinessCardRequestCount')
  }

  findBusinessCard(ownerId: string): Promise<any> {
    return this.post('/api/businessCard/findBusinessCard', {
      owner: ownerId
    })
  }

  readBusinessCard(ownerId: string): Promise<any> {
    return this.post('/api/businessCard/readBusinessCard', {
      owner: ownerId
    })
  }

  getBusinessCardUnreadCount(): Promise<BusinessCardUnreadCount | undefined> {
    return this.get('/api/businessCard/getBusinessCardUnreadCount')
  }

  existsBusinessCardExchangeRequest(userId: string): Promise<any> {
    return this.post('/api/businessCard/existsBusinessCardExchangeRequest', {
      objectId: userId
    })
  }
}
