import HttpRepository from './httpRepository'

export default class DoctorRepository extends HttpRepository {
  createListOfHospital(params: {
    type: string
    name: string
    memo: string
    labels: number[]
    hospitalId: string | undefined
  }): Promise<void> {
    return this.post('/api/lists/hospitals', params)
  }

  deleteListOfHospital(listId: string): Promise<void> {
    return this.delete(`/api/lists/hospitals/${listId}`)
  }

  findListOfHospital(): Promise<any> {
    return this.get('/api/lists/hospitals')
  }

  addListOfHospital(params: {
    hospitalId: string
    objectIds: string[]
  }): Promise<void> {
    return this.post('/api/lists/hospitals/add', params)
  }

  removeListOfHospital(params: {
    objectId: string
    hospitalIds: string[]
  }): Promise<void> {
    return this.post('/api/lists/hospitals/remove', params)
  }

  getListOfHospital(listId: string): Promise<any> {
    return this.get(`/api/lists/hospitals/${listId}`)
  }

  updateListOfHospital(
    listId: string,
    params: {
      name: string
      memo: string
      labels: number[]
      share: string
      hospitalId: string | undefined
    }
  ): Promise<void> {
    return this.put(`/api/lists/hospitals/${listId}`, params)
  }
}
