export const state = () => ({
  firstName: '',
  lastName: '',
  job: '',
  prefectureCode: '',
  prefectureName: '',
  hospitalName: '',
  hospitalAddress: '',
  email: '',
  body: '',
  step: 'input',
  jobList: [
    { text: '医師', value: 1 },
    { text: '薬剤師', value: 2 },
    { text: '看護師', value: 3 },
    { text: '医療ソーシャルワーカー', value: 4 },
    { text: '事務職', value: 5 },
    { text: 'その他', value: 6 }
  ],
  companyCode: '',
  isConfirmed: false,
  inquiryMailAddress: ''
})

export const getters = {}

export const mutations = {
  firstName(state, value) {
    state.firstName = value
  },
  lastName(state, value) {
    state.lastName = value
  },
  job(state, value) {
    state.job = value
  },
  setPrefectureCode(state, value) {
    state.prefectureCode = value
  },
  setPrefectureName(state, payload) {
    state.prefectureName = payload
  },
  setInquiryMailAddress(state, payload) {
    state.inquiryMailAddress = payload
  },
  hospitalName(state, value) {
    state.hospitalName = value
  },
  hospitalAddress(state, value) {
    state.hospitalAddress = value
  },
  email(state, value) {
    state.email = value
  },
  body(state, value) {
    state.body = value
  },
  setCompanyCode(state, value) {
    state.companyCode = value
  },
  setIsConfirmed(state, value) {
    state.isConfirmed = value
  },
  step(state, value) {
    state.step = value
  }
}

export const actions = {
  async send_contact({ state }) {
    await this.$axios.$post('/api/contact', {
      firstName: state.firstName,
      lastName: state.lastName,
      job: state.jobList[state.job - 1].text,
      hospitalName: state.hospitalName,
      prefectureName: state.prefectureName,
      hospitalAddress: state.hospitalAddress,
      email: state.email,
      body: state.body,
      companyCode: state.companyCode,
      isConfirmed: state.isConfirmed
    })
  }
}
