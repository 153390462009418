<template>
  <div class="button-container" :style="containerStyle">
    <VBtn
      :href="href"
      :color="colorProps"
      :outlined="outlined"
      :rounded="rounded"
      :to="to"
      :disabled="disabled"
      :target="target"
      :text="text"
      :loading="loading"
      :type="type"
      :height="height"
      block
      depressed
      :dark="dark"
      :ripple="ripple"
      :gtm-data="gtmData"
      class="button-container-button"
      @click="$emit('click', $event)"
    >
      <div
        :style="{ fontSize, fontWeight }"
        :class="['button-inner', buttonInnerClass]"
      >
        <slot />
      </div>
    </VBtn>
  </div>
</template>

<script>
import { customColorProfileKeys } from '~/helper/colors'
import { isValidColorCode } from '~/helper'

export default {
  props: {
    outlined: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    to: {
      type: String,
      default: undefined
    },
    href: {
      type: String,
      default: undefined
    },
    target: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '12px'
    },
    fontWeight: {
      type: String,
      default: '600'
    },
    type: {
      type: String,
      default: 'button'
    },
    height: {
      type: String,
      default: '28px'
    },
    ripple: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: undefined
    },
    gtmData: {
      type: String,
      default: undefined
    },
    buttonInnerClass: {
      type: String,
      default: undefined
    }
  },
  computed: {
    colorProps() {
      const colors = [
        ...customColorProfileKeys,
        'primary',
        'accent',
        'success',
        'gray',
        'error',
        'white',
        'lightgray',
        'blue'
      ]
      if (!isValidColorCode(this.color) && !colors.includes(this.color)) {
        throw new Error(`想定しないcolorです。${this.color}`)
      }
      const color = this.color

      switch (color) {
        case 'gray':
          return '#2c2c2c'
        case 'lightgray':
          return '#959595'
        case 'blue':
          return '#ECEFF9'
        default:
          return color
      }
    },
    containerStyle() {
      return {
        height: this.height
      }
    }
  }
}
</script>

<style scoped lang="scss">
.button-container {
  .button-container-button::v-deep {
    height: 100% !important;
    padding: 0 !important;
  }
}

.button-container::v-deep {
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: $gray20 !important;
    color: #ffffff !important;
  }
}

.button-inner {
  display: flex;
  align-items: center;
}
</style>
