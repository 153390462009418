import HttpRepository from './httpRepository'

export default class MsRepository extends HttpRepository {
  findManagers(userIds: string[]): Promise<any> {
    return this.post('/api/ms/managers', {
      users: userIds
    })
  }

  fetchMSProfile(msId: string): Promise<any> {
    return this.post('/api/ms/profile', {
      objectId: msId
    })
  }

  updateMSProfile(params: {
    objectId: string
    introduction: string | undefined
    territory: string | undefined
    avatarUrl: string | undefined
  }): Promise<any> {
    return this.patch('/api/ms/profile', params)
  }
}
