import HttpRepository from './httpRepository'
import { toArr } from '~/helper'
export default class SearchRepository extends HttpRepository {
  async fetchDoctorAndHospitalCount(): Promise<any> {
    const data = await this.get(`/api/dcsr/info`)
    return data
  }

  async searchDoctorsByName(
    partOfName: string,
    signupCondition?: number
  ): Promise<any> {
    const res = await this.get('/api/doctor/searchName', {
      params: {
        partOfName,
        signupCondition: signupCondition ?? 0,
        size: 100,
        from: 0
      }
    })

    const total = res.hits.total
    return {
      doctors: res.hits.hits.map(({ _source }: { _source: any }) => _source),
      total
    }
  }

  async searchDoctors(params: {
    partOfName?: string
    lastName?: string
    firstName?: string
    departments?: any
    medicalAreaIds?: number[]
    doctorExpertCategory?: number[]
    specialties?: string[]
    hospitalName?: string
    signupCondition?: number
    offset?: number
    limit?: number
  }): Promise<any> {
    const res = await this.post('/api/doctor/search', {
      partOfName: params.partOfName,
      lastName: params.lastName,
      firstName: params.firstName,
      departments: params.departments ?? '',
      medicalAreaIds: params.medicalAreaIds ? params.medicalAreaIds.join() : '',
      doctorExpertCategory: params.doctorExpertCategory
        ? toArr(params.doctorExpertCategory)
        : [],
      specialties: params.specialties ? toArr(params.specialties) : '',
      hospitalName: params.hospitalName,
      signupCondition: params.signupCondition,
      from: params.offset ?? 0,
      size: params.limit
    })

    const total = res.hits.total
    return {
      doctors: res.hits.hits.map(({ _source }: { _source: any }) => _source),
      total
    }
  }

  async searchHospitalsByName(name: string): Promise<any> {
    const res = await this.post('/api/hospital/search', {
      name
    })
    const total = res.hits.total
    return {
      hospitals: res.hits.hits.map(({ _source }: { _source: any }) => _source),
      total
    }
  }

  async searchMs(params: {
    companyCode?: string
    organization: string
    name: string
    limit?: number
    offset?: number
  }): Promise<any> {
    const res = await this.post('/api/ms/search', {
      companyCode: params.companyCode,
      organization: params.organization,
      name: params.name,
      limit: params.limit,
      offset: params.offset
    })
    return res
  }
}
