// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts_ver16/icomoon.eot?onhs7h");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts_ver16/icomoon.ttf?onhs7h");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts_ver16/icomoon.woff?onhs7h");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts_ver16/icomoon.svg?onhs7h");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#icomoon" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"icomoon\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:400;font-style:normal;font-display:block}[class*=\" icon-\"],[class^=icon-]{font-family:\"icomoon\"!important;speak:never;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-add_notes:before{content:\"\\e1600\"}.icon-Add .path1:before{content:\"\\e1601\";color:#f4f4f4}.icon-Add .path2:before{content:\"\\e1602\";margin-left:-1em;color:#465daa}.icon-cached:before{content:\"\\e1603\"}.icon-cloud_upload:before{content:\"\\e1604\"}.icon-DragIndicator:before{content:\"\\e1605\"}.icon-Help_outline:before{content:\"\\e1606\"}.icon-Person-search:before{content:\"\\e1607\"}.icon-user-Doctor:before{content:\"\\e1608\"}.icon-user-MS:before{content:\"\\e1609\"}.icon-visibillity_off:before{content:\"\\e1610\"}.icon-visibilly:before{content:\"\\e1611\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
