import { NuxtAxiosInstance } from '@nuxtjs/axios'

export default class HttpRepository {
  private axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  get(url: string, options = {}) {
    return this.axios.$get(url, options)
  }

  post(url: string, data?: any) {
    return this.axios.$post(url, data)
  }

  patch(url: string, data?: any) {
    return this.axios.$patch(url, data)
  }

  delete(url: string) {
    return this.axios.$delete(url)
  }

  put(url: string, data?: any) {
    return this.axios.$put(url, data)
  }
}
