














import Vue from 'vue'

export default Vue.extend({
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    overlayColor: {
      type: String,
      default: '#2c2c2c'
    },
    persistent: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: Number,
      default: 0.25
    },
    expire: {
      type: Number,
      default: undefined
    },
    attach: {
      type: String,
      default: undefined
    },
    contentClass: {
      type: String,
      default: undefined
    }
  },
  computed: {
    _isOpen: {
      get(): boolean {
        return this.isOpen
      },
      set(val: boolean) {
        this.$emit('update:isOpen', val)
      }
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal && this.expire) {
        setTimeout(() => {
          this._isOpen = false
        }, this.expire)
      }
    }
  }
})
