import Vue from 'vue'

Vue.mixin({
  methods: {
    backOrTop() {
      if (history.length === 1) {
        this.$router.push('/')
        return
      }

      this.$router.back()
    }
  }
})
