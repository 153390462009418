import { Plugin } from '@nuxt/types'

export type Error = {
  handler: ({ message, error }: { message?: string; error?: any }) => void
}

const plugin: Plugin = (context, inject) => {
  const error: Error = {
    handler: ({ message, error }) => {
      let errorMessage = message
      // axiosのエラーを拾うため
      if (!message && error && error.response && error.response.data) {
        errorMessage = error.response.data
      }
      context.error({
        statusCode: 500,
        message: errorMessage
      })
    }
  }

  context.$error = error
  inject('error', error)
}

export default plugin
