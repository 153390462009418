import { getTopPath } from '../helper/role'
import constant from '~/helper/constant'

export default ({ store, redirect }) => {
  if (
    !store.state.currentUser ||
    store.state.currentUser.role !== constant.roles.coMedical
  ) {
    redirect(getTopPath(store.state.currentUser.role))
  }
}
