export const state = () => ({
  email: '',
  hospitalId: '',
  hospitalName: ''
})

export const mutations = {
  setEmail(state, email) {
    state.email = email
  },
  setHospitalId(state, hospitalId) {
    state.hospitalId = hospitalId
  },
  setHospitalName(state, hospitalName) {
    state.hospitalName = hospitalName
  }
}

export const actions = {
  setHospitalParams({ commit }, { email, hospitalId, hospitalName }) {
    commit('setEmail', email)
    commit('setHospitalId', hospitalId)
    commit('setHospitalName', hospitalName)
  }
}
